import Pusher from 'pusher-js';
import { isEmpty, orderBy } from 'lodash';
import homeActions from 'redux/home/actions';
import authActions from 'redux/auth/actions';
import peopleActions from 'redux/people/actions';
import folderActions from 'redux/folders/actions';
import captureActions from 'redux/capture/actions';
import sharedSparksActions from 'redux/sharedSparks/actions';
import activitiesActions from 'redux/activities/actions';
import { store } from 'redux/store';
import { checkForRunningOrReadyAsyncAction } from 'utility/asyncHelper';
import {
  getHomeActivityId,
  getCurrentProjectId,
  getUserId,
  showInfoModal,
} from 'utility/helpers';
import { checkForRunningOrReadyAsyncActionInterval } from 'components/constants';
import AuthAction from 'redux/auth/actions';
import { initiateDuplicationProgress } from '../components/shared/duplicate/helper';
import {updateGroupsList, updateSharedLinks, updateTrash} from '../pusher/helper';

let getCaptureActionsInterval = '',
  getSignatureActionsInterval = '',
  getSharedSparksActionsInterval = '',
  getFolderActionsInterval = '',
  getBoardsActionsInterval = '';

let pusherConfig = null;

function configPusher(userId) {
  pusherConfig = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  })
  const channel = pusherConfig.subscribe(`App.User.${userId}`);
  pusherEventTypes.map(({ type, action }) =>
      channel.bind(type, (data) => {
        if (data.status === 200) {
          action(data);
        }
      }),
  );
}

export const initiatePusherService = (userId: string = ''): void => {
  if (!pusherConfig) configPusher(userId);
  pusherConfig.connection.bind('connected', () => {
    store.dispatch({
      type: authActions.SET_SOCKET_ID,
      payload: pusherConfig.connection.socket_id,
    });
  });

};

export const destroyPusherConnection = () => {
  if (pusherConfig) pusherConfig.disconnect();
};

export const getCaptureList = (
  isFromPusher: boolean = false,
  loadMore: boolean = false,
  lastItemCreatedAt: string = '',
  actionData: Object = {},
): void => {
  if (isFromPusher) {
    if (!checkForRunningOrReadyAsyncAction()) {
      if (getCaptureActionsInterval) clearInterval(getCaptureActionsInterval);
      const { captureListRecentFetchTime } = store.getState().Capture;
      store.dispatch({
        type: captureActions.GET_CAPTURE_LISTS,
        isFromPusher,
        payload: {
          date: captureListRecentFetchTime,
        },
        actionData,
      });
    } else {
      if (!getCaptureActionsInterval) {
        getCaptureActionsInterval = setInterval(() => {
          getCaptureList(true, undefined, undefined, actionData);
        }, checkForRunningOrReadyAsyncActionInterval);
      }
    }
  } else if (loadMore) {
    store.dispatch({
      type: captureActions.GET_CAPTURE_LISTS,
      loadMore,
      payload: {
        created_at: lastItemCreatedAt,
      },
      actionData,
    });
  } else {
    store.dispatch({
      type: captureActions.GET_CAPTURE_LISTS,
      isInitialAPI: true,
    });
  }
};

export const updateCaptureList = (
  response: [Object] = [],
  isFromPusher: boolean = false,
  actionData: Object = {},
  lastId: string = '',
): void => {
  if (isFromPusher) {
    let removedCaptures = [],
      updatedCaptureList = [],
      remainingItem = [];
    const { captureLists } = store.getState().Capture;
    // lastItemCreatedAt = captureLists[captureLists.length - 1]?.created_at; // Will be used in future
    response.data.map((data: Object): void => {
      if (data.is_deleted) {
        removedCaptures.push(data.id);
      } else if (captureLists.find(({ id }) => id === data.id)) {
        updatedCaptureList.push(data);
      } else {
        remainingItem.push(data);
      }
      return null;
    });

    let newList = captureLists.filter(
      ({ id }) => !removedCaptures.includes(id),
    );
    newList = newList.map(
      (item) =>
        updatedCaptureList.find(({ id }) => id === item.id) ||
        item,
    );
    newList = orderBy(
      [...remainingItem, ...newList],
      [(obj: Object): Date => new Date(obj.created_at), 'id'],
      ['desc', 'desc'],
    );
    store.dispatch({
      type: captureActions.GET_CAPTURE_LISTS_SUCCESS,
      payload: { data: newList },
      capturesLastId: lastId,
    });
  } else {
    store.dispatch({
      type: captureActions.GET_CAPTURE_LISTS_SUCCESS,
      payload: response,
      capturesLastId: lastId,
    });
  }
};

export const getSignatureList = (isFromPusher: boolean = false): void => {
  if (isFromPusher) {
    if (!checkForRunningOrReadyAsyncAction()) {
      if (getSignatureActionsInterval)
        clearInterval(getSignatureActionsInterval);
      store.dispatch({
        type: peopleActions.GET_SIGNATURE_LIST,
        isFromPusher,
      });
    } else {
      if (!getSignatureActionsInterval) {
        getSignatureActionsInterval = setInterval(() => {
          getSignatureList(true);
        }, checkForRunningOrReadyAsyncActionInterval);
      }
    }
  } else {
    store.dispatch({
      type: peopleActions.GET_SIGNATURE_LIST,
    });
  }
};

export const updateSignatureList = (
  response: [Object] = [],
  loadMore: boolean = false,
  lastId: String = '',
): void => {
  store.dispatch({
    type: peopleActions.GET_SIGNATURE_LIST_SUCCESS,
    payload: response,
    lastId,
  });
};

export const getSharedSparksList = (isFromPusher: boolean = false): void => {
  if (isFromPusher) {
    if (!checkForRunningOrReadyAsyncAction()) {
      if (getSharedSparksActionsInterval)
        clearInterval(getSharedSparksActionsInterval);
      store.dispatch({
        type: sharedSparksActions.GET_SHARED_LIST,
        resetArray: true,
      });
    } else {
      if (!getSharedSparksActionsInterval) {
        getSharedSparksActionsInterval = setInterval(() => {
          getSignatureList(true);
        }, checkForRunningOrReadyAsyncActionInterval);
      }
    }
  } else {
    store.dispatch({
      type: sharedSparksActions.GET_SHARED_LIST,
      resetArray: true,
    });
  }
};

export const updateSharedSparksList = (response: [Object] = []): void => {
  store.dispatch({
    type: sharedSparksActions.GET_SHARED_SPARKS_SUCCESS,
    payload: response,
  });
};

type getFolderItemPayload = {|
  sortBy: string,
  isHomeActivity: boolean,
  noLoader: boolean,
  tempIdData: [Object],
|};

export const payloadValue = () => ({
  sortBy: store.getState().Folder?.sortFoldersBy || 'newest_first',
  isHomeActivity: false,
  noLoader: false,
  tempIdData: [],
});

export const getFolderAndFolderItem = (
  isFromPusher: boolean = false,
  {
    sortBy,
    isHomeActivity,
    noLoader,
    tempIdData,
  }: getFolderItemPayload = payloadValue(),
  actionData: Object = {},
): void => {
  const { selectedActivity } = store.getState().Folder,
    { homeActivity } = store.getState().Home,
    { jotsProjectDetails } = store.getState().jotsReducer;
  if (isFromPusher) {
    if (!checkForRunningOrReadyAsyncAction()) {
      if (getFolderActionsInterval) clearInterval(getFolderActionsInterval);
      const recentFetchTime = isHomeActivity
        ? store.getState().Home.homeFolderListRecentFetchTime
        : store.getState().Folder.folderListRecentFetchTime;
      store.dispatch({
        type: folderActions.GET_NODES_LIST,
        sortFoldersBy:
          sortBy === 'sparks_only'
            ? 'sparks_only'
            : (isHomeActivity
                ? homeActivity?.document_view?.view === 'list'
                : selectedActivity?.document_view?.view === 'list') &&
              sortBy === 'free_space'
            ? 'newest_first'
            : sortBy,
        projectId: isHomeActivity
          ? jotsProjectDetails?.id
          : getCurrentProjectId(),
        isHomeActivity,
        isFromPusher,
        recentFetchTime,
        actionData,
      });
    } else {
      if (!getFolderActionsInterval) {
        getFolderActionsInterval = setInterval(() => {
          getFolderAndFolderItem(true);
        }, checkForRunningOrReadyAsyncActionInterval);
      }
    }
  } else {
    if (isHomeActivity) {
      store.dispatch({
        type: homeActions.SET_HOME_LOADER,
        payload: !noLoader,
      });
    }
    store.dispatch({
      type: folderActions.GET_NODES_LIST,
      sortFoldersBy:
        sortBy === 'sparks_only'
          ? 'sparks_only'
          : (isHomeActivity
              ? homeActivity?.document_view?.view === 'list'
              : selectedActivity?.document_view?.view === 'list') &&
            sortBy === 'free_space'
          ? 'newest_first'
          : sortBy,
      projectId: isHomeActivity ? getHomeActivityId() : getCurrentProjectId(),
      isHomeActivity,
      tempIdData,
      noLoader,
    });
  }
};

const getSortedFolders = (foldersList: [Object], sortBy: string): [Object] => {
  switch (sortBy) {
    case 'free_space': {
      return orderBy(
        foldersList,
        [(obj: Object): Date => new Date(obj.last_sync_at)],
        ['desc'],
      );
    }
    case 'newest_first': {
      return orderBy(
        foldersList,
        [(obj: Object): Date => new Date(obj.updated_at)],
        ['desc'],
      );
    }
    case 'oldest_first': {
      return orderBy(
        foldersList,
        [(obj: Object): Date => new Date(obj.updated_at)],
        ['asc'],
      );
    }
    default: {
      return orderBy(foldersList, ['position'], ['asc']);
    }
  }
};

type updateFolderItemPayload = {|
  sortFoldersBy: string,
  isHomeActivity: boolean,
  projectId: string,
  tempIdData: [Object],
  recentFetchTime: Date,
|};

export const updateFolderAndFolderItem = (
  response: Object,
  {
    isHomeActivity,
    sortFoldersBy,
    projectId,
    tempIdData,
    recentFetchTime,
  }: updateFolderItemPayload,
  isFromPusher: boolean = false,
  actionData: object = {},
): void => {
  if (isFromPusher) {
    const { folderList, itemList } = response;
    let updatedFoldersList = store.getState().Folder.foldersList,
      updatedFolderItemsList = store.getState().Folder.folderItemsList;
    if (isHomeActivity) {
      updatedFoldersList = store.getState().Home.homeFoldersList;
      updatedFolderItemsList = store.getState().Home.homeFolderItemsList;
    }
    if (!isEmpty(itemList)) {
      for (let item in itemList) {
        updatedFolderItemsList[item] = itemList[item];
      }
    }
    let removedFoldersList = [],
      updatedFolderList = [],
      remainingItem = [];
    folderList.map((data: Object): void => {
      if (data.is_delete) {
        return removedFoldersList.push(data.node_id);
      } else if (
        updatedFoldersList.find((folder) => folder.node_id === data.node_id)
      ) {
        return updatedFolderList.push(data);
      }
      return remainingItem.push(data);
    });

    let newList = updatedFoldersList.filter(
      ({ node_id }) => !removedFoldersList.includes(node_id),
    );
    newList = newList.map(
      (item) =>
        updatedFolderList.find(({ node_id }) => node_id === item.node_id) ||
        item,
    );
    newList = getSortedFolders([...newList, ...remainingItem], sortFoldersBy);
    store.dispatch({
      type: isHomeActivity
        ? homeActions.UPDATE_HOME_NODE_LIST
        : folderActions.GET_NODES_LIST_SUCCESS,
      payload: {
        folderList: newList,
        itemList: updatedFolderItemsList,
      },
      sortFoldersBy,
      projectId,
      tempIdData,
    });
  } else {
    store.dispatch({
      type: isHomeActivity
        ? homeActions.UPDATE_HOME_NODE_LIST
        : folderActions.GET_NODES_LIST_SUCCESS,
      payload: response,
      sortFoldersBy,
      projectId,
      tempIdData,
    });
  }
};

export const updateUserInfo = (response: [Object] = []): void => {
  store.dispatch({
    type: authActions.GET_USER_DETAILS_SUCCESS,
    payload: response,
    isFromPusher: true,
  });
  if (response.deactivated) {
    store.dispatch({ type: AuthAction.LOGOUT_USER });
  }
};

export const getHomeBoardDetails = (
  isFromPusher: boolean = false,
  loadMore: boolean = false,
  lastItemCreatedAt: string = '',
  actionData: Object = {},
): void => {
  store.dispatch({
    type: homeActions.GET_HOME_ACTIVITY_DETAILS,
    isFromPusher,
    projectId: actionData?.projectId,
  });
};

export const getBoardsList = (
  isFromPusher: boolean = false,
  loadMore: boolean = false,
  lastItemCreatedAt: string = '',
  actionData: Object = {},
): void => {
  if (isFromPusher) {
    if (!checkForRunningOrReadyAsyncAction()) {
      if (getBoardsActionsInterval) {
        clearInterval(getBoardsActionsInterval);
      }
    } else {
      if (!getBoardsActionsInterval) {
        getBoardsActionsInterval = setInterval(() => {
          getBoardsList(true);
        }, checkForRunningOrReadyAsyncActionInterval);
      }
    }
  }
};

export const updateBoardsList = (
  response: [Object] = [],
  isFromPusher: boolean = false,
  loadMore: boolean = false,
  payload: Object = {},
  actionData: object = {},
): void => {
  if (isFromPusher) {
    let removedBoards = [],
      updatedBoardsList = [],
      remainingBoard = [];
    const { activityLists } = store.getState().Activities,
      { selectedActivity } = store.getState().Folder;
    response.data.map((data: Object): void => {
      if (
        actionData?.state === 'deleted' &&
        data.project_id === actionData?.projectId &&
        data.is_deleted
      ) {
        if (selectedActivity?.project_id === actionData?.projectId) {
          store.dispatch({
            type: activitiesActions.UPDATE_NODES_LISTS,
            payload: actionData?.projectId,
            activityDelete: true,
          });
          store.dispatch({
            type: folderActions.CHANGE_SELECTED_ACTIVITY,
            selectedActivity: {},
          });
          localStorage.setItem(`spark-projectId-${getUserId()}`, '');
        }
        removedBoards.push(data.project_id);
      } else if (
        [...activityLists.active.data, ...activityLists.archived.data].some(
          ({ project_id }) => project_id === data.project_id,
        )
      ) {
        updatedBoardsList.push(data);
      } else {
        remainingBoard.push(data);
      }
      return null;
    });
    let newList = [
      ...activityLists.active.data,
      ...activityLists.archived.data,
    ];
    newList = newList.filter(
      ({ project_id }) => !removedBoards.includes(project_id),
    );
    newList = newList.map((item) => ({
      ...item,
      ...updatedBoardsList.find(
        ({ project_id }) => project_id === item.project_id,
      ),
    }));
    newList = [...remainingBoard, ...newList];
    let newActiveList = orderBy(
        newList.filter(({ archived }) => !archived),
        ['position'],
        ['asc'],
      ),
      newArchivedList = orderBy(
        newList.filter(({ archived }) => archived),
        [(obj: Object): Date => new Date(obj.updated_at)],
        ['desc'],
      );

    store.dispatch({
      type: activitiesActions.GET_ACTIVITIES_LISTS_SUCCESS,
      payload: {
        data: {
          active: { data: newActiveList },
          archived: { data: newArchivedList },
        },
      },
    });
  } else {
    store.dispatch({
      type: activitiesActions.GET_ACTIVITIES_LISTS_SUCCESS,
      payload: response,
    });
  }
};

export const endSession = (response: [Object] = []): void => {
  const activeSessions = store.getState().Auth.activeSessions;
  let currentSession = activeSessions?.sessions.filter(
    (active) => active?.is_current_device,
  );
  if (
    currentSession.length > 0 &&
    !response?.active_sessions.includes(currentSession[0].id)
  ) {
    showInfoModal('signed_out', 'end_session_desc', 'OK');
  }
};

const pusherEventTypes = [
  {
    type: 'duplication_progress',
    action: initiateDuplicationProgress,
  },
  {
    type: 'capture_type',
    action: (data) => {
      if (data.state === 'moved' || data.state === 'deleted') {
        store.dispatch({
          type: captureActions.DELETE_CAPTURE_SUCCESS,
          payload: data.itemIds || data.items,
        });
      } else {
        getCaptureList(true, undefined, undefined, data);
      }
    },
  },
  {
    type: 'user_info_type',
    action: (data) => {
      updateUserInfo(data);
    },
  },
  // {
  //   type: 'shared_spark_type',
  //   action: () => {
  //     getSharedSparksList(true);
  //   },
  // },
  {
    type: 'signature_type',
    action: () => {
      getSignatureList(true);
    },
  },
  // {
  //   type: 'project_node_item_type',
  //   action: (data) => {
  //     if (
  //       data.isProjectItem ||
  //       (data.state === 'recovered' && data?.projectIds)
  //     ) {
  //       if (data.state === 'updated' && checkIsHomeActivity(data?.projectId)) {
  //         getHomeBoardDetails(true, undefined, undefined, { ...data });
  //       } else {
  //         getBoardsList(true, undefined, undefined, { ...data });
  //       }
  //     }
  //     if (
  //       [getHomeActivityId(), getCurrentProjectId()].includes(data.projectId) ||
  //       [getHomeActivityId(), getCurrentProjectId()].includes(data.to) ||
  //       (data.state === 'recovered' && data?.projectIds)
  //     ) {
  //       let isHomeActivity = getHomeActivityId() === data.projectId;
  //       if (data.state === 'moved') {
  //         if (data.to && data.from) {
  //           store.dispatch({
  //             type: isHomeActivity
  //               ? homeActions.DELETE_HOME_NODE_SUCCESS
  //               : folderActions.DELETE_NODE_SUCCESS,
  //             payload: data.nodeId,
  //           });
  //           isHomeActivity = getHomeActivityId() === data.to;
  //         } else if (data?.items?.length) {
  //           // Todo: will work on future
  //           // store.dispatch({
  //           //   type: checkIsHomeActivity(data.projectId)
  //           //     ? homeActions.DELETE_HOME_NODE_ITEMS
  //           //     : folderActions.DELETE_NODE_ITEMS,
  //           //   items: data.items,
  //           //   nodeId: data.nodeId,
  //           //   projectId: data.projectId,
  //           // });
  //         }
  //       }
  //       if (!data.projectId) {
  //         data.projectId = data.projectIds[0];
  //       }
  //       if (data.projectId) {
  //         getFolderAndFolderItem(
  //           true,
  //           {
  //             sortBy: isHomeActivity
  //               ? store.getState().Home.homeSortFoldersBy
  //               : store.getState().Folder.sortFoldersBy,
  //             isHomeActivity,
  //           },
  //           { ...data },
  //         );
  //       }
  //     }
  //     if (data.state === 'recovered') {
  //       store.dispatch({ type: authActions.GET_USER_DETAILS });
  //       store.dispatch({
  //         type: trashActions.GET_TRASH_BOARD_GROUPS_LIST,
  //         payload: { isFromPusher: true },
  //       });
  //       store.dispatch({
  //         type: trashActions.GET_TRASH_BOARDS_LIST,
  //         payload: { isFromPusher: true },
  //       });
  //       store.dispatch({
  //         type: trashActions.GET_TRASH_FOLDERS_LIST,
  //         payload: { isFromPusher: true },
  //       });
  //       store.dispatch({
  //         type: trashActions.GET_TRASH_DOCUMENTS_LIST,
  //         payload: { isFromPusher: true },
  //       });
  //       store.dispatch({
  //         type: trashActions.GET_TRASH_ITEMS_LIST,
  //         payload: { isFromPusher: true },
  //       });
  //     }
  //   },
  // },
  {
    type: 'account_status',
    action: (data) => {
      endSession(data);
    },
  },
  {
    type: 'trash_type',
    action: (data) => {
      console.log({data});
      const { currentTab } = store.getState().Settings;
      currentTab.includes('trash') && updateTrash(data);
    },
  },
  {
    type: 'group_type',
    action: (data) => {
      console.log({data});
      const { currentTab } = store.getState().Settings;
      currentTab.includes('activities') && updateGroupsList();
    },
  },
  {
    type: 'sharing_type',
    action: (data) => {
      const { currentTab } = store.getState().Settings;
      currentTab.includes('shared') && updateSharedLinks(data);
    },
  },
  //have to check later
  // {
  //   type: 'trash_type',
  //   action: (data) => {
  //     store.dispatch({ type: authActions.GET_USER_DETAILS });
  //     if (data.type === 'all') {
  //       store.dispatch({
  //         type: trashActions.GET_TRASH_BOARD_GROUPS_LIST,
  //         payload: { isFromPusher: true },
  //       });
  //       store.dispatch({
  //         type: trashActions.GET_TRASH_BOARDS_LIST,
  //         payload: { isFromPusher: true },
  //       });
  //       store.dispatch({
  //         type: trashActions.GET_TRASH_FOLDERS_LIST,
  //         payload: { isFromPusher: true },
  //       });
  //       store.dispatch({
  //         type: trashActions.GET_TRASH_DOCUMENTS_LIST,
  //         payload: { isFromPusher: true },
  //       });
  //       store.dispatch({
  //         type: trashActions.GET_TRASH_ITEMS_LIST,
  //         payload: { isFromPusher: true },
  //       });
  //     } else {
  //       if (data.type === 'projects') {
  //         store.dispatch({
  //           type: trashActions.GET_TRASH_BOARD_GROUPS_LIST,
  //           payload: { isFromPusher: true },
  //         });
  //         store.dispatch({
  //           type: trashActions.GET_TRASH_BOARDS_LIST,
  //           payload: { isFromPusher: true },
  //         });
  //       }
  //       if (data.type === 'nodes') {
  //         store.dispatch({
  //           type: trashActions.GET_TRASH_FOLDERS_LIST,
  //           payload: { isFromPusher: true },
  //         });
  //         store.dispatch({
  //           type: trashActions.GET_TRASH_DOCUMENTS_LIST,
  //           payload: { isFromPusher: true },
  //         });
  //       }
  //       if (data.type === 'items')
  //         store.dispatch({
  //           type: trashActions.GET_TRASH_ITEMS_LIST,
  //           payload: { isFromPusher: true },
  //         });
  //     }
  //   },
  // },
];
