import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import commonSaga from './common/saga';
import tourSaga from './tour/saga';
import subscriptionSaga from './subscription/saga';
import settingsSaga from './settings/saga';
export function* rootSaga() {
  yield all([
    authSagas(),
    commonSaga(),
    subscriptionSaga(),
    settingsSaga(),
    tourSaga(),
  ]);
}

// Function used to inject sagas into existing sagas (async saga import logic)
export const createSagaInjector = (runSaga, rootSaga) => {
  const injectedSagas = new Map();
  const isInjected = (key) => injectedSagas.has(key);
  const injectSaga = (key, saga) => {
    if (isInjected(key)) return;
    const task = runSaga(saga);
    injectedSagas.set(key, task);
  };
  injectSaga('root', rootSaga);
  return injectSaga;
}
