import actions from '../redux/trash/actions';
import { store } from '../redux/store';
import sharedSparksActions from '../redux/sharedSparks/actions';
import boardGroupsAction from '../redux/boardGroups/actions';

export function updateGroupsList() {
  store.dispatch({
    type: boardGroupsAction.GET_BOARD_GROUPS_LIST,
  });
}
export function updateTrash(data) {
  if (data?.type === 'groups') {
    store.dispatch({
      type: actions.GET_TRASH_BOARD_GROUPS_LIST,
      payload: { isFromPusher: true },
    });
  }
  if (data?.type === 'projects') {
    store.dispatch({
      type: actions.GET_TRASH_BOARDS_LIST,
      payload: { isFromPusher: true },
    });
  }
  if (data?.type === 'nodes') {
    store.dispatch({
      type: actions.GET_TRASH_FOLDERS_LIST,
      payload: { isFromPusher: true },
    });
  }
  if (data?.type === 'documents') {
    store.dispatch({
      type: actions.GET_TRASH_DOCUMENTS_LIST,
      payload: { isFromPusher: true },
    });
  }
  if (data?.type === 'items') {
    store.dispatch({
      type: actions.GET_TRASH_ITEMS_LIST,
      payload: { isFromPusher: true },
    });
  }
}
export function updateSharedLinks(data) {
  const isFromPusher = true;
  if (data?.type === 'projects') {
    store.dispatch({
      type: sharedSparksActions.GET_SHARED_BOARDS,
      isFromPusher,
    });
  }
  if (data?.type === 'nodes') {
    store.dispatch({
      type: sharedSparksActions.GET_SHARED_FOLDERS,
      isFromPusher,
    });
  }
  if (data?.type === 'documents') {
    store.dispatch({
      type: sharedSparksActions.GET_SHARED_DOCUMENTS,
      isFromPusher,
    });
  }
}
