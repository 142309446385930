import React from 'react';
import actions from 'redux/addBar/actions';
import captureActions from 'redux/capture/actions';
import homeActions from 'redux/home/actions';
import folderActions from 'redux/folders/actions';
import asyncActions from 'redux/async/actions';
import commonActions from 'redux/common/actions';
import { store } from 'redux/store';
import { addAsyncAction } from 'utility/asyncHelper';
import {
  audioSupportedFormats,
  imageSupportedFormats,
  videoSupportedFormats,
} from 'components/constants';
import {
  addBarVisibility,
  checkIsHomeActivity,
  getBlobUrl,
  getFolderDetails,
  getTempCaptureListItem,
  getTempFolderListItem,
  getUserId,
  isHomeActivityNode,
  randomIdGenerate,
  alertView,
  showNotification,
  tempUpdateFolderList,
  Translate,
  downloadUnknownTypeFile,
  saveNoteTitle,
} from 'utility/helpers';
import Compressor from 'compressorjs';
import addbarActions from 'redux/addBar/actions';
import jotsActions from '../../redux/jots/actions';
import boardGroupActions from '../../redux/boardGroups/actions';
import { getNodeDetailsForDocument } from '../shared/helper';
import { isCloudAreaListView, updateListViewData } from '../boardGroups/helper';
import addBarActions from 'redux/addBar/actions';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { Spin } from 'antd';

export function userSpecificLocationScenarios(err){
  switch (err.code) {
    case err.PERMISSION_DENIED:
      alertView({
        alertText: 'location_not_enabled',
        type: 'error',
        nodeId:'add-bar',
      });
      break;
    case err.POSITION_UNAVAILABLE:
      console.error('Location information is unavailable');
      break;
    case err.TIMEOUT:
      console.error('Request to get user location timed out');
      break;
    default:
      console.error('An unknown error occurred');
      break;
  }
}

export function getLocationDetails(placeId, map, isCurrentLocation) {
  const request = {
    placeId,
    fields: [
      'name',
      'formatted_address',
      'place_id',
      'address_component',
      'formatted_phone_number',
      'website',
    ],
  };

  map &&
    new window.google.maps.places.PlacesService(map).getDetails(
      request,
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          if (isCurrentLocation) {
            store.dispatch({
              type: actions.GET_CURRENT_PLACE_DETAILS,
              details: place,
            });
          } else {
            store.dispatch({
              type: actions.GET_PLACE_DETAILS_OF_SELECTED,
              details: place,
            });
          }
          return place;
        }
      },
    );
}

export function getLocationAddress(
  position,
  setAddress,
  setLocationDetail,
  noSetDetails,
  isFromPinnedLocation = false,
) {
  const geocoder = new window.google.maps.Geocoder(),
    latlng = new window.google.maps.LatLng(position);
  geocoder.geocode(
    {
      latLng: latlng,
    },
    function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results[1]) {
          const {
            formatted_address,
            place_id,
            address_components,
          } = results[1];
          if (setAddress === 'currentLocation') {
            let addressSplit = '';
            address_components.map((data, index) => {
              if (index === 0) {
                return '';
              } else {
                addressSplit = `${addressSplit}${data.long_name} `;
                return '';
              }
            });
            store.dispatch({
              type: actions.SET_CURRENT_LOCATION_DETAILS,
              payload: {
                currentAddress: formatted_address,
                currentLocation: position,
                isCurrentLocationEnabled: true,
                currentLocationPlaceId: place_id,
                currentAddressValue: addressSplit,
                currentLocationNodeId: window.currentLocationNodeId,
                currentAddressLongName: address_components[0].long_name,
                isCurrentLocationLoader: false,
              },
              isLocationVisible: true,
              currentLocationNodeId: window.currentLocationNodeId,
            });
            if (window.globalMap && !noSetDetails) {
              getLocationDetails(place_id, window.globalMap);
            }
            if (noSetDetails) {
              getLocationDetails(place_id, window.globalMap, true);
            }
          } else {
            if (setLocationDetail) {
              store.dispatch({
                type: actions.GET_PLACE_ID_VALUE,
                placeIdValue: place_id,
                isFromPinnedLocation,
              });
            }
          }
        }
      }
    },
  );
}

function currentLocationPosition(position) {
  getLocationAddress(
    { lat: position.coords.latitude, lng: position.coords.longitude },
    'currentLocation',
    '',
    true,
  );
}

function errorHandlerCurrentLocationPosition(err) {
  store.dispatch({
    type: actions.SET_CURRENT_LOCATION_DETAILS,
    payload: {
      isCurrentLocationEnabled: false,
      isCurrentLocationLoader: false,
    },
    isLocationVisible: false,
    currentLocationNodeId: window.currentLocationNodeId,
  });
  if (err.code === 1) {
    console.error('Error: Access is denied!');
  } else if (err.code === 2) {
    console.error('Error: Position is unavailable!');
  }
}

export function getCurrentLocation(nodeId) {
  store.dispatch({
    type: actions.SET_CURRENT_LOCATION_DETAILS,
    payload: {
      isCurrentLocationLoader: true,
    },
    isLocationVisible: true,
    currentLocationNodeId: nodeId,
  });
  const options = { timeout: 60000 };
  if (navigator.geolocation) {
    window.currentLocationNodeId = nodeId;
    navigator.geolocation.getCurrentPosition(
      currentLocationPosition,
      errorHandlerCurrentLocationPosition,
      options,
    );
  } else {
    alert('Sorry, browser does not support geolocation!');
  }
}

export function onGetFileDuration(fileObj, addBarType) {
  return new Promise(function (resolve) {
    let fileUrl = window.URL.createObjectURL(fileObj);
    let myMedia = document.createElement(addBarType);
    myMedia.src = fileUrl;
    myMedia.oncanplaythrough = function (e) {
      let myMediaDuration = new Date(e.currentTarget.duration * 1000)
        .toISOString()
        .substr(11, 8);
      resolve({ duration: myMediaDuration });
    };
  });
}

function closeMediaModal(isEdit, isCapture, data) {
  if (isEdit) {
    if (isCapture) {
      store.dispatch({
        type: captureActions.UPDATE_CAPTURE_EDIT_MODAL,
        payload: '',
        visibility: false,
      });
    } else {
      let folderDetails = getFolderDetails(data?.node_id);
      store.dispatch({
        type: checkIsHomeActivity(folderDetails?.project_id)
          ? homeActions.EDIT_HOME_MODAL_VISIBLE
          : folderActions.EDIT_MODAL_VISIBLE,
        payload: false,
        data: '',
      });
    }
  } else {
    store.dispatch({
      type: actions.ADD_BAR_MODAL_VISIBLE,
      payload: false,
      addBarType: 'unknown',
    });
  }
}

function editMediaItem(
  unknownFile,
  location,
  isCapture,
  addBarType,
  data,
  sizeValue,
) {
  let payload = {
      content: {
        new_file_name: unknownFile,
      },
      location,
    },
    captureType = 'unknown';
  if (isCapture) {
    if (addBarType === 'audio' || addBarType === 'video') {
      captureType = addBarType;
    } else if (addBarType === 'photo') {
      captureType = 'image';
    } else {
      payload = {
        ...payload,
        name: unknownFile,
      };
    }
    store.dispatch({
      type: captureActions.UPDATE_CAPTURE_ITEM,
      payload,
      captureType,
      itemId: data.id,
    });
  } else {
    const nodeId = data.parent_id,
      itemId = data.id;
    if (addBarType !== 'unknown') {
      payload = {
        ...payload,
        caption: data?.content?.caption ? data.content.caption : '',
      };
    }
    if (addBarType === 'photo') {
      store.dispatch({
        type: folderActions.UPDATE_PHOTO,
        payload,
        nodeId,
        itemId,
        sizeValue,
      });
    } else if (addBarType === 'video') {
      store.dispatch({
        type: folderActions.UPDATE_VIDEO,
        payload,
        nodeId,
        itemId,
        sizeValue,
      });
    } else if (addBarType === 'audio') {
      store.dispatch({
        type: folderActions.UPDATE_AUDIO,
        payload,
        nodeId,
        itemId,
        sizeValue,
      });
    } else {
      store.dispatch({
        type: folderActions.ADD_CAPTURE_ITEM,
        payload,
        nodeId,
        itemId,
        sizeValue,
      });
    }
  }
}

function addMediaFile(
  isFolderItems,
  fileData,
  nodeId,
  selectedFileSize,
  localUrl,
  localDuration,
  currentLatLong,
  nodeDetails,
  location,
  sizeValue,
  selectedTitle,
  folderDetails,
  selectedFileName,
  addBarType,
  isMultipleFilesUpload,
  localVideoThumbNail,
  isFromCopyPaste,
  isFromDroppableOverlay,
) {
  const { selectedActivity } = store.getState().Folder,
    { homeActivity } = store.getState().Home,
    { addItemProjectId, addBarDetails } = store.getState().addBar,
    nodeDetailsData = getNodeDetailsForDocument(nodeDetails?.node_id);
  const getAlertMessage = () => {
    if (addBarType === 'photo') {
      return 'photo_successfully_added';
    } else if (addBarType === 'video') {
      return 'video_successfully_added';
    }
    return 'audio_successfully_added';
  };
  if (!addBarDetails.isCapture && !isFromCopyPaste && !isFromDroppableOverlay) {
    if (['photo', 'video', 'audio'].includes(addBarType)) {
      const content = {
          path: '',
          size: selectedFileSize,
          url: localUrl,
          caption: null,
          thumbnail:
            addBarType === 'photo'
              ? localUrl
              : addBarType === 'video'
                ? localVideoThumbNail
                : '',
          duration: localDuration,
          metadata: [],
        },
        tempResponseData = tempUpdateFolderList(
          nodeId,
          content,
          currentLatLong,
          addBarType === 'photo' ? 'image' : addBarType,
          addBarType === 'photo'
            ? 'photo_successfully_added'
            : addBarType === 'video'
              ? 'video_successfully_added'
              : 'audio_successfully_added',
          addBarType === 'photo' ? 'image' : addBarType,
          selectedFileName,
          undefined,
          isMultipleFilesUpload,
          addBarDetails.container,
        ),
        captureItemData = {
          action: actions.ADD_FOLDER_MEDIA_ITEM,
          payload: {
            payload: {
              nodeDetails,
              location,
              name: selectedFileName,
              duration: localDuration,
            },
            addBarType: addBarType === 'photo' ? 'image' : addBarType,
            captureItems: isFolderItems,
            sizeValue,
            fileSize: selectedFileSize,
            title: selectedTitle,
            tempId: tempResponseData.temp_id,
            nodeId: tempResponseData.node_id,
            projectId: nodeDetailsData?.project_id || addItemProjectId,
            activityName: checkIsHomeActivity(folderDetails?.project_id)
              ? homeActivity.name
              : selectedActivity.name,
            folderName: folderDetails?.name ? folderDetails.name : '',
            isHomeActivity: checkIsHomeActivity(folderDetails?.project_id),
          },
          nodeId: tempResponseData.node_id,
          tempId: tempResponseData.temp_id,
          callbackId: randomIdGenerate('callback-id'),
        },
        payload = {
          data: localUrl,
          fileName: selectedFileName,
          uploadProgress: {
            uploadedSize: 0,
            totalSize: fileData.size,
            completedPercent: 0,
          },
          mediaType: addBarType === 'photo' ? 'image' : addBarType,
          captureItemData,
          nodeId: tempResponseData.node_id,
          projectId: nodeDetailsData?.project_id || addItemProjectId,
          activityName: checkIsHomeActivity(folderDetails?.project_id)
            ? homeActivity.name
            : selectedActivity.name,
          folderName: folderDetails?.name ? folderDetails.name : '',
          isFolderUpdate: true,
          isCurrentLocationProcess: true,
          parentContainer: addBarDetails.container,
          parent: addBarDetails.parent,
          alertMessage: getAlertMessage(),
          parent_id: addBarDetails.parentId,
          location,
        };
      const functionProps = {
        tempData: tempResponseData,
        nodeDetails,
        content,
        name: selectedFileName,
        executeApi: false,
        alertMessage: getAlertMessage(),
      };
      addItemFromAddBar(functionProps);
      addAsyncAction(asyncActions.ASYNC_UPLOAD_MEDIA_FILE, payload);
    } else {
      const content = {
          path: '',
          size: selectedFileSize,
          url: localUrl,
          caption: null,
          thumbnail:
            addBarType === 'photo'
              ? localUrl
              : addBarType === 'video'
                ? localVideoThumbNail
                : '',
          duration: localDuration,
          metadata: [],
        },
        tempResponseData = tempUpdateFolderList(
          nodeId,
          content,
          currentLatLong,
          addBarType === 'photo' ? 'image' : addBarType,
          'unknown_type_file_successfully_added',
          addBarType === 'photo' ? 'image' : addBarType,
          selectedFileName?.replaceAll(' ', '-'),
          undefined,
          isMultipleFilesUpload,
        ),
        captureItemData = {
          action: actions.ADD_FOLDER_MEDIA_ITEM,
          payload: {
            payload: {
              nodeDetails,
              location,
              duration: localDuration,
              name: selectedFileName,
            },
            addBarType: addBarType === 'photo' ? 'image' : addBarType,
            captureItems: isFolderItems,
            sizeValue,
            fileSize: selectedFileSize,
            title: selectedTitle,
            isFromUnknown: true,
            projectId:
              addBarDetails.projectId ||
              nodeDetailsData?.project_id ||
              addItemProjectId,
            activityName: checkIsHomeActivity(folderDetails?.project_id)
              ? homeActivity?.name
              : selectedActivity?.name,
            folderName: folderDetails?.name ? folderDetails.name : '',
            isHomeActivity: checkIsHomeActivity(folderDetails?.project_id),
            tempId: tempResponseData.temp_id,
          },
          tempId: tempResponseData.temp_id,
          callbackId: randomIdGenerate('callback-id'),
        },
        payload = {
          data: localUrl,
          fileName: selectedFileName,
          name: selectedFileName,
          mediaType: addBarType === 'photo' ? 'image' : addBarType,
          captureItemData,
          uploadProgress: {
            uploadedSize: 0,
            totalSize: fileData.size,
            completedPercent: 0,
          },
          nodeId: tempResponseData.node_id,
          projectId: nodeDetailsData?.project_id || addItemProjectId,
          activityName: checkIsHomeActivity(folderDetails?.project_id)
            ? homeActivity.name
            : selectedActivity.name,
          folderName: folderDetails?.name ? folderDetails.name : '',
          isFolderUpdate: true,
          isCurrentLocationProcess: true,
          parentContainer: addBarDetails.container, parent: addBarDetails.parent,
          alertMessage: 'unknown_type_file_successfully_added',
          parent_id: addBarDetails.parentId,
          location,
        };
      const functionProps = {
        tempData: tempResponseData,
        nodeDetails,
        content,
        name: selectedFileName,
        executeApi: false,
        alertMessage: 'unknown_type_file_successfully_added',
      };
      addItemFromAddBar(functionProps);
      addAsyncAction(asyncActions.ASYNC_UPLOAD_MEDIA_FILE, payload);
    }
    if (isFromCopyPaste && !isMultipleFilesUpload) {
      showNotification(
        'success',
        <Translate>folder_paste_success_message</Translate>,
        2,
        null,
        null,
        'success',
      );
    }
  } else {
    if (['photo', 'video', 'audio'].includes(addBarType)) {
      const tempResponseData = getTempCaptureListItem(
          {
            path: '',
            size: selectedFileSize,
            url: localUrl,
            caption: null,
            thumbnail:
              addBarType === 'photo'
                ? localUrl
                : addBarType === 'video'
                ? localVideoThumbNail
                : '',
            duration: localDuration,
            metadata: [],
          },
          0,
          0,
          undefined,
          addBarType === 'photo' ? 'image' : addBarType,
          selectedFileName,
        ),
        captureItemData = {
          action: actions.ADD_CAPTURE_ITEM,
          payload: {
            payload: {
              nodeDetails,
              location,
              name: selectedFileName,
              duration: localDuration,
            },
            addBarType: addBarType === 'photo' ? 'image' : addBarType,
            captureItems: isFolderItems,
            sizeValue,
            fileSize: selectedFileSize,
            title: selectedTitle,
            isFromUnknown: true,
            tempId: tempResponseData.id,
          },
          tempId: tempResponseData.id,
        },
        payload = {
          data: localUrl,
          fileName: selectedFileName,
          mediaType: addBarType === 'photo' ? 'image' : addBarType,
          captureItemData,
          isCurrentLocationProcess: true,
          parentContainer: addBarDetails.container,
          alertMessage: getAlertMessage(),
          parent: addBarDetails.parent,
          parent_id: addBarDetails.parentId,
          location,
        };
      addAsyncAction(asyncActions.ASYNC_UPLOAD_MEDIA_FILE, payload);
    } else {
      const tempResponseData = getTempCaptureListItem(
          {
            path: '',
            size: selectedFileSize,
            url: localUrl,
            caption: null,
            thumbnail:
              addBarType === 'photo'
                ? localUrl
                : addBarType === 'video'
                ? localVideoThumbNail
                : '',
            metadata: [],
          },
          0,
          0,
          undefined,
          'unknown',
          selectedFileName,
        ),
        captureItemData = {
          action: actions.ADD_CAPTURE_ITEM,
          payload: {
            payload: {
              nodeDetails,
              location,
              name: selectedFileName,
            },
            addBarType: addBarType === 'photo' ? 'image' : addBarType,
            captureItems: isFolderItems,
            sizeValue,
            fileSize: selectedFileSize,
            title: selectedTitle,
            isFromUnknown: true,
            tempId: tempResponseData.id,
          },
          tempId: tempResponseData.id,
        },
        payload = {
          data: localUrl,
          fileName: selectedFileName,
          mediaType: addBarType === 'photo' ? 'image' : addBarType,
          captureItemData,
          isCurrentLocationProcess: true,
          parentContainer: addBarDetails.container, parent: addBarDetails.parent,
          alertMessage: 'location_successfully_added',
          location,
        };
      addAsyncAction(asyncActions.ASYNC_UPLOAD_MEDIA_FILE, payload);
    }
    if (isFromCopyPaste && !isMultipleFilesUpload) {
      showNotification(
        'success',
        <Translate>capture_success_message</Translate>,
        2,
        null,
        null,
        'success',
      );
    }
  }
  store.dispatch({
    type: actions.SET_ADD_BAR_MODAL_STATUS,
    addBarType: 'unknown',
    nodeIdType: isFolderItems ? nodeDetails.node_id : 'add-bar',
  });
}

function getAddBarType(file) {
  if (
    imageSupportedFormats.includes(file.type) ||
    videoSupportedFormats.includes(file.type) ||
    audioSupportedFormats.includes(file.type)
  ) {
    if (file.type.includes('audio/')) {
      return 'audio';
    } else if (file.type.includes('video/')) {
      return 'video';
    } else {
      return 'photo';
    }
  } else {
    return 'unknown';
  }
}

export const uploadValues = async (
  selectedFileType,
  fileDataObject,
  fileAddBarType,
  isEdit,
  isCapture,
  data,
  fileData,
  planStorageLimit,
  isFolderItems,
  folderItemsList,
  homeFolderItemsList,
  revisionPermissions,
  unknownFile,
  addBarType,
  selectedFileSize,
  localUrl,
  localDuration,
  selectedTitle,
  selectedFileName,
  fileList,
  currentNodeId,
  previewData,
  currentLocation,
  currentAddress,
  localVideoThumbNail,
  isFromCopyPaste,
  isFromDroppableOverlay,
) => {
  const { totalSize } = store.getState().addBar,
    { plan } = store.getState().Auth;
  if (
    videoSupportedFormats.includes(selectedFileType) ||
    audioSupportedFormats.includes(selectedFileType)
  ) {
    if (
      selectedFileType.includes('audio') ||
      selectedFileType.includes('video')
    ) {
      if (fileData?.localDuration) {
        localDuration = fileData.localDuration;
      } else {
        await onGetFileDuration(fileDataObject, fileAddBarType).then(
          (durationObj) => {
            localDuration = durationObj?.duration;
          },
        );
      }
    }
    if (selectedFileType.includes('video')) {
      if (fileData?.localVideoThumbNail) {
        localVideoThumbNail = fileData.localVideoThumbNail;
      } else {
        let url = window.URL.createObjectURL(fileDataObject);
        await getVideoThumbNailUrl(url).then((thumbNailObj?) => {
          localVideoThumbNail = thumbNailObj?.thumbNail;
        })
      }
    }
  }
  // close Media Modal
  closeMediaModal(isEdit, isCapture, data);

  const currentLatLong = {
      latitude: currentLocation.lat,
      longitude: currentLocation.lng,
      address: currentAddress,
    },
    nodeDetails = currentNodeId !== 'add-bar' ? { node_id: currentNodeId } : {},
    sizeValue =
      (fileData?.size || previewData?.size || 0) + planStorageLimit.used;
  const currentLocationValues = store.getState().addBar.currentLocationValues;
  const isLocationVisible = store.getState().addBar.addBarDetails.isLocationVisible;
  const location =
    isLocationVisible &&
    currentLocationValues?.['addbar-modal']?.isCurrentLocationEnabled
      ? { address: currentLocationValues['addbar-modal']?.currentAddress }
      : null;
  let addedSizeValueOfAllItems = sizeValue || fileData?.size || 0,
    folderDetails;
  if (nodeDetails?.node_id) {
    folderDetails = getFolderDetails(nodeDetails.node_id);
  }
  if (isFolderItems) {
    if (folderItemsList?.[currentNodeId]?.data?.length) {
      folderItemsList[currentNodeId].data.forEach((node) => {
        if (['image', 'video', 'audio', 'unknown'].includes(node.type)) {
          addedSizeValueOfAllItems += parseInt(node.content.size);
        }
      });
    } else if (homeFolderItemsList?.[currentNodeId]?.data?.length) {
      homeFolderItemsList[currentNodeId].data.forEach((node) => {
        if (['image', 'video', 'audio', 'unknown'].includes(node.type)) {
          addedSizeValueOfAllItems += parseInt(node.content.size);
        }
      });
    }
  }
  if (
    plan !== 'Premium'
      ? localStorage.getItem(`usedData-${getUserId()}`) <= 100 ||
        localStorage.getItem(`usedData-${getUserId()}`) === null
      : sizeValue < planStorageLimit.storageLimit &&
        (isFolderItems && revisionPermissions.document_upload_size
          ? addedSizeValueOfAllItems < revisionPermissions.document_upload_size
          : true)
  ) {
    if (isEdit) {
      editMediaItem(
        unknownFile,
        location,
        isCapture,
        addBarType,
        data,
        sizeValue,
      );
    } else {
      addMediaFile(
        isFolderItems,
        fileData,
        currentNodeId,
        selectedFileSize,
        localUrl,
        localDuration,
        currentLatLong,
        nodeDetails,
        location,
        sizeValue,
        selectedTitle,
        folderDetails,
        selectedFileName?.replace(/ /gi, '-'),
        fileAddBarType,
        fileList?.length > 1,
        localVideoThumbNail,
        isFromCopyPaste,
        isFromDroppableOverlay,
      );
    }
  } else {
    store.dispatch({
      type: commonActions.SET_COMMON_ALERT_MODAL_VISIBLE,
      module: 'upgradeModal',
      payload: {
        upgradeModalVisible: true,
        upgradeModalMessage: (
          <Translate>
            {sizeValue < planStorageLimit.storageLimit
              ? 'document_storage_limit_exceeded'
              : 'storage_limit_exceed'}
          </Translate>
        ),
      },
    });
  }
  store.dispatch({
    type: actions.STORE_TOTAL_USED_STORAGE,
    payload: totalSize
      ? totalSize + (fileData?.size || previewData?.size || 0)
      : sizeValue,
  });
};

export const initiateFileUpload = ({
  fileList = [],
  currentNodeId = 'add-bar',
  nodeId = null,
  isEdit = false,
  isCapture = false,
  data = {},
  previewData = null,
  isFolderItems = false,
  addBarType = 'addBarType',
  selectedTitle = null,
  unknownFile = null,
  isFullScreen = false,
  isSafari = false,
  isFromCopyPaste = false,
  isFromDroppableOverlay = false,
  fileUploadCheck,
  parent = 'jots',
}) => {
  if (isFullScreen && isSafari) {
    // This close modal method is only for safari full screen folder.
    store.dispatch({
      type: actions.ADD_BAR_MODAL_VISIBLE,
      payload: false,
      addBarType: 'unknown',
    });
  }
  const { planStorageLimit, revisionPermissions } = store.getState().Auth,
    { currentLocation, currentAddress } = store.getState().addBar,
    { folderItemsList, isOpenFullScreenFolderModal } = store.getState().Folder,
    { homeFolderItemsList } = store.getState().Home,
    onMultipleFilesUpload = async () => {
      for (let fileData of fileList) {
        let fileDataObject = fileData?.originFileObj || fileData;
        let selectedFileName = fileDataObject?.name?.replace(/ /gi, '-'),
          selectedFileSize = fileDataObject?.size,
          selectedFileType = fileDataObject?.type,
          fileAddBarType =
            fileDataObject?.addBarType || getAddBarType(fileDataObject),
          localUrl = window.URL.createObjectURL(fileDataObject),
          localDuration,
          localVideoThumbNail;
        if (
          fileData.addBarType === 'photo' &&
          ['image/jpeg', 'image/bmp'].includes(fileDataObject.type)
        ) {
          new Compressor(fileDataObject, {
            quality: 0.8,
            mimeType: 'image/jpeg',
            async success(compressedFile) {
              selectedFileName = compressedFile?.name?.replace(/ /gi, '-');
              selectedFileSize = compressedFile?.size;
              selectedFileType = compressedFile?.type;
              localUrl = window.URL.createObjectURL(compressedFile);
              uploadValues(
                selectedFileType,
                compressedFile,
                fileAddBarType,
                isEdit,
                isCapture,
                data,
                compressedFile,
                planStorageLimit,
                isFolderItems,
                folderItemsList,
                homeFolderItemsList,
                revisionPermissions,
                unknownFile,
                addBarType,
                selectedFileSize,
                localUrl,
                localDuration,
                selectedTitle,
                selectedFileName,
                fileList,
                currentNodeId,
                previewData,
                currentLocation,
                currentAddress,
                localVideoThumbNail,
                isFromCopyPaste
              );
            },
            error(err) {
              console.error(err.message);
            },
          });
        } else {
          uploadValues(
            selectedFileType,
            fileDataObject,
            fileAddBarType,
            isEdit,
            isCapture,
            data,
            fileData,
            planStorageLimit,
            isFolderItems,
            folderItemsList,
            homeFolderItemsList,
            revisionPermissions,
            unknownFile,
            addBarType,
            selectedFileSize,
            localUrl,
            localDuration,
            selectedTitle,
            selectedFileName,
            fileList,
            currentNodeId,
            previewData,
            currentLocation,
            currentAddress,
            localVideoThumbNail,
            isFromCopyPaste,
            isFromDroppableOverlay,
          );
        }
      }
    };
  const multipleUploadPromise = new Promise(function (resolve, reject) {
    resolve(onMultipleFilesUpload());
  });
  fileUploadCheck &&
    multipleUploadPromise
      .then(function () {
        if (fileList?.length > 1) {
          // if (!isFromCopyPaste) {
          //   if (currentNodeId === 'add-bar') {
          //     capturesAlertView({
          //       parentContainer: 'captures',
          //       alertText: 'capture_multiple_files_upload_success_message',
          //       type: 'success',
          //       delay: 3,
          //     });
          //   } else {
          //     if (parent === 'jots') {
          //       jotsAlertView({
          //         alertText: 'folder_multiple_files_upload_success_message',
          //         type: 'success',
          //         delay: 3,
          //         data: 'Jots',
          //       });
          //     } else {
          //       cloudAreaAlertView({
          //         alertText: 'folder_multiple_files_upload_success_message',
          //         type: 'success',
          //         delay: 3,
          //         data: 'Folder',
          //       });
          //     }
          //   }
          // }
          if (isFromCopyPaste) {
            showNotification(
              'success',
              !!isOpenFullScreenFolderModal ? (
                <Translate data={'folder'}>
                  folder_multiple_files_upload_success_message
                </Translate>
              ) : (
                <Translate>
                  capture_multiple_files_upload_success_message
                </Translate>
              ),
              2,
              null,
              null,
              'success',
            );
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
};

export function updateCaptureListWithCurrentLocation(tempResponseData) {
  const locationAccess = store.getState().Auth.locationAccess;
  if (navigator.geolocation && locationAccess.status) {
    navigator.geolocation.getCurrentPosition((position) => {
      tempResponseData.captured_longitude = position.coords.longitude;
      tempResponseData.captured_latitude = position.coords.latitude;
      store.dispatch({
        type: captureActions.UPDATE_CAPTURE_LISTS,
        payload: tempResponseData,
        isCurrentLocationUpdate: true,
      });
    });
  }
}

export function getEmptyCurrentLocationDetails(latitude, longitude) {
  const geocoder = new window.google.maps.Geocoder(),
    latlng = new window.google.maps.LatLng({
      lat: latitude,
      lng: longitude,
    });
  geocoder.geocode(
    {
      latLng: latlng,
    },
    function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const placeId = results[1].place_id,
          request = {
            placeId,
            fields: [
              'name',
              'formatted_address',
              'place_id',
              'address_component',
              'formatted_phone_number',
              'website',
            ],
          };
        window.globalMap &&
          new window.google.maps.places.PlacesService(
            window.globalMap,
          ).getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              store.dispatch({
                type: actions.GET_PLACE_DETAILS_OF_SELECTED,
                details: place,
              });
            }
          });
      }
    },
  );
}

export function getVideoThumbNailUrl(url) {
  if (url) {
    return new Promise((resolve) => {
      let canvas = document.createElement('canvas');
      let video = document.createElement('video');
      video.muted = true;
      video.src = url;
      video.crossOrigin = 'anonymous';
      video.autoplay = true;
      video.onloadeddata = (e) => {
        canvas
          .getContext('2d')
          .drawImage(video, 0, 0, canvas.width, canvas.height);
        video.pause();
        resolve({ thumbNail: getBlobUrl(canvas.toDataURL('image/png')) });
      };
    });
  }
}

export function getFilesUploadPermission(
  fileList,
) {
  const { planStorageLimit } = store.getState().Auth;
   let addedSizeOfFileList = 0;
  fileList.forEach((data) => {
    if (data.size) {
      addedSizeOfFileList += data.size;
    }
  });
  return addedSizeOfFileList + planStorageLimit.used <
      planStorageLimit.storageLimit;
}

export function calculatedStorage(data) {
  const { displayStorage } = store.getState().addBar,
    notificationStorageData = displayStorage
      ? displayStorage
      : localStorage.getItem(`usedData-${getUserId()}`) &&
        localStorage.getItem(`usedData-${getUserId()}`);
  let usedDataPercentage = '';
  if (
    data >= 50 &&
    data < 80 &&
    (!notificationStorageData >= 50 || notificationStorageData === null)
  ) {
    usedDataPercentage = 'halfwayThere';
  } else if (
    data >= 80 &&
    data < 90 &&
    (notificationStorageData < 80 || notificationStorageData === null)
  ) {
    usedDataPercentage = 'eightyPercent';
  } else if (
    data >= 90 &&
    data < 98 &&
    (notificationStorageData < 90 || notificationStorageData === null)
  ) {
    usedDataPercentage = 'ninetyPercent';
  } else if (
    data >= 98 &&
    data < 100 &&
    (notificationStorageData < 98 || notificationStorageData === null)
  ) {
    usedDataPercentage = 'ninetyEightPercent';
  } else if (
    data >= 100 &&
    (notificationStorageData < 100 || notificationStorageData === null)
  ) {
    usedDataPercentage = 'hundredPercent';
  } else {
    usedDataPercentage = '';
  }
  if (usedDataPercentage) {
    store.dispatch({
      type: actions.NOTIFICATION_MODAL_VISIBILITY,
      payload: true,
      usedDataPercentage,
      percentage: data,
    });
  }
}
export function emptyTotalSize() {
  store.dispatch({
    type: actions.STORE_TOTAL_USED_STORAGE,
    payload: '',
  });
  localStorage.getItem(`usedData-${getUserId()}`) &&
    localStorage.removeItem(`usedData-${getUserId()}`);
}
export function addArrayValues(accumulator, a) {
  return accumulator + a;
}
export function getUsedStoragePercentage(data, fileUpload) {
  const { storageLimit, used } = store.getState().Auth.planStorageLimit,
    { totalSize } = store.getState().addBar,
    totalUsedStorage =
      fileUpload === 'fileUpload'
        ? data
        : totalSize
        ? parseInt(totalSize) + data
        : used + data;
  store.dispatch({
    type: addbarActions.STORE_TOTAL_USED_STORAGE,
    payload: totalUsedStorage,
  });
  return Math.round((totalUsedStorage / storageLimit) * 100);
}

export function resetVideoRecorder() {
  store.dispatch({
    type: actions.RESET_VIDEO_RECORDER,
  });
}

export function resetAddbarNodeId() {
  store.dispatch({
    type: folderActions.ADDBAR_COMPONENTS_VISIBLE,
  });
}

export function getCapturesNoteItemData() {
  const { captureNoteItemData } = store.getState().addBar;
  return captureNoteItemData;
}
export function getFolderNoteItemData() {
  const { folderNoteItemData } = store.getState().Folder;
  return folderNoteItemData;
}

export function setExpandCollapseAddBar(data = false) {
  store.dispatch({
    type: actions.SET_EXPAND_COLLAPSE_ADDBAR,
    payload: data,
  });
}

export function closeAddBar(nodeId) {
  const { expandCollapseAddBar } = store.getState().addBar,
    { expandedFolderAddbar } = store.getState().Folder,
    isCaptureAddBar = nodeId === 'add-bar';
  if (expandCollapseAddBar && isCaptureAddBar) {
    setExpandCollapseAddBar();
  }
  if (expandedFolderAddbar && !isCaptureAddBar) {
    addBarVisibility();
  }
}
export default function modalVisible({
  nodeId,
  type,
  parent,
  projectId,
  position,
  name,
}) {
  if (type === 'note') {
    saveNoteTitle({ noteTitle: '' });
  }
  renderAddBar({
    addBarTitle: name,
    addBarType: type,
    nodeId: nodeId,
    parent,
    projectId,
    position,
    parentId: nodeId,
  });
}
export function nodeAddBarVisibility(nodeId) {
  store.dispatch({
    type: folderActions.FOLDER_ADD_BAR_VISIBILITY,
    payload: nodeId,
  });
}

export function itemPosition(nodeId) {
  const {
      homeTargetZonePosition,
      homeTargetZonePositionNodeId,
    } = store.getState().Home,
    {
      targetZonePosition,
      targetZonePositionNodeId,
    } = store.getState().Folder,
    isHomeActivity = isHomeActivityNode(nodeId);
  let getTargetZonePositionNodeId = targetZonePositionNodeId,
    getTargetZonePosition = targetZonePosition,
    // itemsList = folderItemsList,
    position = '';
  if (isHomeActivity) {
    getTargetZonePositionNodeId = homeTargetZonePositionNodeId;
    getTargetZonePosition = homeTargetZonePosition;
    // itemsList = homeFolderItemsList;
  }
  if (getTargetZonePositionNodeId === nodeId) {
    position = getTargetZonePosition;
  }
  return position;
}

export function getAction() {
  const { addBarDetails } = store.getState().addBar;
  const parentType = ['jots', 'boards', 'folder'];
  let type = 'ADD_FOLDER_ITEM';
  if (addBarDetails.addBarType === 'contact') {
    type = 'ADD_SIGNATURE_TO_FOLDER';
  } else if (parentType.includes(addBarDetails.parent)) {
    type = 'ADD_PROJECT_ITEM';
  }
  return type;
}

export function addIndividualItemsToJots(content, tempId) {
  const altType = [
    { orgType: 'photo', altType: 'image' },
    { orgType: 'list', altType: 'note' },
  ];
  const { addBarDetails } = store.getState().addBar;
  if (altType.some((obj) => obj.orgType === addBarDetails.addBarType)) {
    addBarDetails.addBarType = altType.find(
      (obj) => obj.orgType === addBarDetails.addBarType,
    ).altType;
  }
  const tempData = getTempFolderListItem(
    null,
    content,
    0,
    0,
    undefined,
    addBarDetails?.addBarType,
    <Translate>untitled</Translate>, //Todo: Need to add name
    tempId,
  );
  store.dispatch({
    type: jotsActions.ADD_JOTS_INDIVIDUAL_ITEM_FROM_ADD_BAR,
    payload: tempData,
  });
}

export function editIndividualJotsItem(data, executeApi = true) {
  store.dispatch({
    type: jotsActions.EDIT_JOTS_INDIVIDUAL_ITEM_FROM_CONTEXT_MENU,
    payload: data.tempData,
  });
  if (executeApi) {
    addAsyncAction(captureActions.UPDATE_CAPTURE_ITEM, {
      payload: {
        content: data?.content,
        location: null,
      },
      captureType: data?.addBarType,
      itemId: getCapturesNoteItemData()?.id || data?.tempData?.id,
      isCurrentLocationProcess: true,
    });
  }
}

export function editIndividualBoardItem(data, isJotsActivity, executeApi = true) {
  const tempName = data.tempData?.content?.description?.name || data.tempData?.content?.name || data.tempData?.name
  // TODO: Card-2148 Need to handle list view, removing list view condition for now
 if (!isJotsActivity) {
    store.dispatch({
      type: boardGroupActions.EDIT_INDIVIDUAL_ITEM_FROM_CONTEXT_MENU,
      payload: { ...data.tempData, name: tempName, description: data?.payload?.payload?.description || data?.tempData?.description },
    });
  } else if (isJotsActivity) {
    store.dispatch({
      type: jotsActions.EDIT_JOTS_INDIVIDUAL_ITEM_FROM_CONTEXT_MENU,
      payload: { ...data.tempData, name: tempName, description: data?.payload?.payload?.description || data?.tempData?.description },
    });
  }
  if (executeApi) {
    let name;
    if (data?.addBarType === 'url') {
      name = data.content.title;
    } else if (data?.addBarType === 'location') {
      name = data?.content?.location_name;
    } else if (data?.addBarType === 'contact') {
      name = data?.content?.first_name?.value;
    } else {
      name = data?.name;
    }
    addAsyncAction(addbarActions.UPDATE_ITEM, {
      id: data.tempData.id,
      payload: {
        content: data?.content,
        location: null,
        type: data?.addBarType,
        name,
        description: data.description,
        parent_id: data.tempData.parent_id,
      },
    });
  }
}

export function editJotsDocumentItem(data, executeApi = true) {
  store.dispatch({
    type: jotsActions.EDIT_JOTS_DOCUMENT_ITEMS_FROM_CONTEXT_MENU,
    payload: data.tempData,
  });
  if (executeApi) {
    addAsyncAction(addbarActions.UPDATE_ITEM, {
      id: data.tempData.id,
      payload: {
        content: data?.content,
        location: null,
        type: data?.addBarType,
        description: data.description,
        parent_id: data.tempData.parent_id,
      },
    });
  }
}

export function editFullScreenDocumentItem(data, executeApi = true) {
  const { documentProjectId, documentNodeId } = store.getState().commonReducer;
  store.dispatch({
    type: commonActions.EDIT_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEMS,
    payload: data.tempData,
  });
  if (executeApi) {
    addAsyncAction(folderActions[data.action], {
      content: data.content,
      nodeId: documentNodeId || data.tempData.node_id || '',
      itemId: getFolderNoteItemData()?.id || data.tempData?.id,
      projectId: documentProjectId,
      isFolderUpdate: true,
      addBarType: data?.addBarType,
      isCurrentLocationProcess: true,
    });
  }
}

export function editDocumentItem(data, executeApi = true) {
  store.dispatch({
    type: boardGroupActions.EDIT_DOCUMENT_ITEMS_FROM_CONTEXT_MENU,
    payload: data.tempData,
  });
  if (executeApi) {
    addAsyncAction(addbarActions.UPDATE_ITEM, {
      id: data.tempData.id,
      payload: {
        content: data?.content,
        location: null,
        type: data?.addBarType,
        description: data.description,
        parent_id: data.tempData.parent_id,
      },
    });
  }
}

export function editFolderItem(data, executeApi = true) {
  store.dispatch({
    type: boardGroupActions.EDIT_FOLDER_ITEMS_FROM_CONTEXT_MENU,
    payload: data.tempData,
  });
  if (executeApi) {
    addAsyncAction(addbarActions.UPDATE_ITEM, {
      id: data.tempData.id,
      payload: {
        content: data?.content,
        location: null,
        type: data?.addBarType,
        description: data.description,
        parent_id: data.tempData.parent_id,
      },
    });
  }
}

export function determineEditOptionsForItem() {
  const { isJotsActivity, isIndividualItem } = store.getState().commonReducer;
  return {
    editIndividualItem: isIndividualItem,
    editJotsDocumentItem: isJotsActivity && !isIndividualItem,
    editDocumentItem: !isJotsActivity && !isIndividualItem,
    editFolderItem: !isJotsActivity && isIndividualItem,
    isJotsActivity,
  };
}

export function determineReducerAction(data) {
  // Todo: Move outside add-bar related logics into this function in future
  let { tempData = null, nodeId = null } = data;
  const { addBarDetails } = store.getState().addBar;
  const { parent } = addBarDetails;
  if (tempData.type === 'location') {
    tempData.name = tempData?.content?.description?.name;
  } else if (tempData.type === 'url') {
    tempData.name = tempData?.content?.title;
  } else if (tempData.name === '') {
    tempData.name = `SP_${Date.now()}`;
  }
  const cloudAreaListView = isCloudAreaListView();
  if (parent === 'jots_document') {
    store.dispatch({
      type: jotsActions.ADD_JOTS_DOCUMENT_ITEM_FROM_ADD_BAR,
      payload: tempData,
      nodeId,
    });
  } else if (parent === 'files_document' && !cloudAreaListView) {
    store.dispatch({
      type: boardGroupActions.ADD_DOCUMENT_ITEM_FROM_ADD_BAR,
      payload: tempData,
      nodeId,
    });
  } else if (
    (parent === 'boards' || parent === 'folder') &&
    !cloudAreaListView
  ) {
    store.dispatch({
      type: boardGroupActions.ADD_INDIVIDUAL_ITEM_FROM_ADD_BAR_TO_CLOUD_AREA,
      payload: tempData,
    });
  } else if (parent === 'jots') {
    store.dispatch({
      type: jotsActions.ADD_JOTS_INDIVIDUAL_ITEM_FROM_ADD_BAR,
      payload: tempData,
    });
  } else if (parent === 'list_view_fullscreen') {
    store.dispatch({
      type: commonActions.ADD_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM,
      payload: tempData,
    });
  }
}

export function closeAddBarModal(addBarType) {
  store.dispatch({
    type: actions.ADD_BAR_MODAL_VISIBLE,
    payload: false,
    addBarType,
  });
}

export function addItemFromAddBar(data) {
  const { addBarDetails } = store.getState().addBar;
  const {
    tempData = null,
    nodeDetails = null,
    content = null,
    description = null,
    executeApi = true,
    alertMessage = '',
    name = '',
    position,
    parentId,
  } = data;
  const isLocationVisible = store.getState().addBar.addBarDetails
    ?.isLocationVisible;
  const currentLocationValues = store.getState().addBar.currentLocationValues;
  const location =
    isLocationVisible &&
    currentLocationValues?.['addbar-modal']?.isCurrentLocationEnabled
      ? { address: currentLocationValues['addbar-modal']?.currentAddress }
      : null;
  const altType = [
    { orgType: 'photo', altType: 'image' },
    { orgType: 'list', altType: 'note' },
  ];
  if (altType.some((obj) => obj.orgType === addBarDetails.addBarType)) {
    addBarDetails.addBarType = altType.find(
      (obj) => obj.orgType === addBarDetails.addBarType,
    ).altType;
  }
  // Todo: Need to resolve projectId and nodeId key duplication
  const payload = {
    addBarType:
      addBarDetails.addBarType === 'currentLocation'
        ? 'location'
        : addBarDetails.addBarType,
    node_id: addBarDetails?.nodeId,
    nodeId: addBarDetails?.nodeId,
    project_id: addBarDetails.projectId,
    projectId: addBarDetails.projectId,
    folderName: nodeDetails?.name,
    isHomeActivity:
      addBarDetails.parent === 'jots' ||
      addBarDetails.parent === 'jots_document',
    isFolderUpdate: true,
    tempId: tempData.id,
    isCurrentLocationProcess: true,
    parent: addBarDetails.parent,
    parentContainer: addBarDetails.container,
    alertMessage,
  };
  let temporaryId;
  if (addBarDetails.addBarType === 'contact') {
    const { selectedSignatures } = store.getState().addBar;
    const signatureIds = selectedSignatures.map((sign) => sign.id);
    payload.payload = {
      signature_ids: signatureIds,
      type: addBarDetails.addBarType,
      captured_location_info: location || tempData?.captured_location_info,
      name: selectedSignatures[0]?.content?.first_name?.value,
      position: addBarDetails?.position,
    };
    payload.addBarType = 'contact';
    temporaryId = selectedSignatures.map(
      ({ id }) => `item-id-${id}`,
    );
  } else {
    payload.payload = {
      captured_location_info: location || tempData?.captured_location_info,
      content,
      description,
      type:
        addBarDetails.addBarType === 'list' ? 'note' : addBarDetails.addBarType,
      position: addBarDetails?.position || position,
    };
    payload.tempId = tempData.id;
    temporaryId = [tempData.id];
    payload.payload.name = addBarDetails?.name || name;
  }
  payload.payload.parent_id = addBarDetails?.parentId || parentId;
  // TODO: Remove the corresponding reducer actions used in determineReducerAction function. Need to check once and remove the required actions
  // determineReducerAction(reducerData);
  if (executeApi) {
    addAsyncAction(actions[getAction()], payload, temporaryId);
  }
}

export function preventCreateNewNote(data) {
  store.dispatch({
    type: commonActions.SAVE_NOTE_CONTENT,
    payload: data,
  });
}

export function renderAddBar(addBar = {}) {
  const {
    data = {},
    title = null,
    addBarType = undefined,
    isEdit = false,
    nodeId = null,
    isCapture,
    parent = null,
    isMediaType = false,
    addBarTitle,
    position,
    parentId,
  } = addBar;
  const isHomeActivity = parent === 'jots' || parent === 'jots_document';
  let addBarDetails;
  if (isCapture) {
    addBarDetails = {
      visibility: true,
      addBarTitle: isEdit ? addBarTitle : 'capture',
      addBarType,
      data,
      isEdit,
      nodeId,
      isCapture,
    };
  } else {
    let setAddBarTitle;
    if (isEdit || parent === 'jots_document' || parent === 'files_document') {
      setAddBarTitle = addBarTitle;
    } else if (parent === 'jots') {
      setAddBarTitle = 'Jots';
    } else if (parent === 'folder') {
      setAddBarTitle = title;
    }
    addBarDetails = {
      visibility: true,
      addBarTitle: setAddBarTitle,
      addBarType,
      data,
      isEdit,
      container:
        parent === 'list_view_fullscreen'
          ? 'direct'
          : isHomeActivity
          ? 'jots'
          : 'boards',
      parent,
      isMediaType,
      isCapture: false,
      position,
      parentId,
    };
  }
  store.dispatch({
    type: actions.ADD_BAR_DETAILS,
    addBarDetails,
  });
}

export function resetAddBar(reset = false) {
  const { addBarDetails } = store.getState().addBar;
  const preventResetType = [
    'photo',
    'video',
    'audio',
    'unknown',
    'image',
    'url',
    'contact',
    'note',
  ];
  if (
    addBarDetails.addBarType === 'note' &&
    (addBarDetails.parent === 'boards' || addBarDetails.parent === 'folder')
  ) {
    updateListViewData();
  }
  if (preventResetType.includes(addBarDetails.addBarType) && !reset) {
    store.dispatch({
      type: addbarActions.ADD_BAR_DETAILS,
      addBarDetails: {
        visibility: false,
        isLocationVisible: false,
      },
    });
  } else {
    store.dispatch({ type: addbarActions.RESET_ADD_BAR });
  }
}

export function itemsNativeEvent(data, parent, ids) {
  if (data.type === 'url') {
    window.open(data.content.url, '_blank');
  } else if (data.type === 'unknown') {
    downloadUnknownTypeFile(data.content.url, data.name);
  } else if (!['unknown', 'url'].includes(data.type)) {
    if (data.type === 'note') {
      store.dispatch({
        type: commonActions.SET_NOTE_CREATE_SAVE_STATE,
        payload: {
          isNoteCreateSave: false,
          isNoteCreateApiInitiated: true,
        },
      });
    }
    if (data.type === 'note') {
      saveNoteTitle({ noteTitle: data?.name });
    }
    renderAddBar({
      title: 'addBarTitle',
      addBarType: data.type,
      data,
      isEdit: true,
      parent,
      isMediaType:
        data.type === 'image' || data.type === 'video' || data.type === 'audio',
      addBarTitle: data?.name,
      parentId: data.parent_id,
    });
  }
}

export function determineListOrder(data = {}) {
  const { existingItems = [], payload = {}, filter = 'newest_first' } = data;
  switch (filter) {
    case 'newest_first': {
      return [
        ...(Array.isArray(payload) ? payload : [payload]),
        ...existingItems,
      ];
    }
    case 'oldest_first': {
      return [
        ...existingItems,
        ...(Array.isArray(payload) ? payload : [payload]),
      ];
    }
    case 'a_to_z': {
      return sortAtoZ([
        ...existingItems,
        ...(Array.isArray(payload) ? payload : [payload]),
      ]);
    }
    case 'z_to_a': {
      return sortZtoA([
        ...existingItems,
        ...(Array.isArray(payload) ? payload : [payload]),
      ]);
    }
    default: {
      return [
        ...existingItems,
        ...(Array.isArray(payload) ? payload : [payload]),
      ];
    }
  }
}
export function determineListOrderForContact(data={}) {
  const { existingItems = [], payload = [], filter = 'newest_first' } = data;
  switch (filter) {
    case 'newest_first': {
      return [...payload, ...existingItems];
    }
    case 'oldest_first': {
      return [...existingItems, ...payload];
    }
    case 'a_to_z': {
      return sortAtoZ([...existingItems, ...payload]);
    }
    case 'z_to_a': {
      return sortZtoA([...existingItems, ...payload]);
    }
    default: {
      return [...existingItems, ...payload];
    }
  }
}

export function sortAtoZ(array) {
  return array.sort((a, b) => {
    const nameA = a?.name?.toLowerCase();
    const nameB = b?.name?.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

export function sortZtoA(array) {
  return array.sort((a, b) => {
    const nameA = a?.name?.toLowerCase();
    const nameB = b?.name?.toLowerCase();

    if (nameA > nameB) {
      return -1;
    }
    if (nameA < nameB) {
      return 1;
    }
    return 0;
  });
}

export function setAddBarModalLoader({ loader = false }) {
  store.dispatch({
    type: addbarActions.SET_ADD_BAR_MODAL_LOADER,
    payload: {
      loader,
    },
  });
}

export function closeAddBarModalAndDisableLoader({ addBarType = null }) {
  if (!['note', 'image', 'video', 'audio', 'unknown'].includes(addBarType)) {
    setAddBarModalLoader({ loader: false });
    resetAddBar();
  }
}

export function jotsOrMyFilesNoteEditActionHelper({
  addBarItemParent,
  noteItemEditData,
  payloadContent,
}) {
  let editDispatchActionType;
  switch (addBarItemParent) {
    case 'jots': {
      editDispatchActionType =
        jotsActions.EDIT_JOTS_INDIVIDUAL_ITEM_FROM_CONTEXT_MENU;
      break;
    }
    case 'folder': {
      editDispatchActionType =
        boardGroupActions.EDIT_FOLDER_ITEMS_FROM_CONTEXT_MENU;
      break;
    }
    case 'jots_document': {
      editDispatchActionType =
        jotsActions.EDIT_JOTS_DOCUMENT_ITEMS_FROM_CONTEXT_MENU;
      break;
    }
    case 'files_document': {
      editDispatchActionType =
        boardGroupActions.EDIT_DOCUMENT_ITEMS_FROM_CONTEXT_MENU;
      break;
    }
    default: {
      editDispatchActionType =
        jotsActions.EDIT_JOTS_INDIVIDUAL_ITEM_FROM_CONTEXT_MENU;
    }
  }
  // Reducer Action
  store.dispatch({
    type: editDispatchActionType,
    payload: noteItemEditData,
  });

  // API Call
  store.dispatch({
    type: addBarActions.UPDATE_ITEM,
    payload: {
      id: noteItemEditData.id,
      payload: {
        content: payloadContent,
        captured_location_info: noteItemEditData?.captured_location_info,
        type: 'note',
        name: noteItemEditData?.name,
        description: noteItemEditData.description,
        parent_id: noteItemEditData.parent_id,
      },
    },
  });
}

export function captureNoteEditActionHelper({
  noteItemEditData,
  location,
  payloadContent,
  isFromNoteHeader = false,
}) {
  store.dispatch({
    type: captureActions.UPDATE_CAPTURE_ITEM_SUCCESS,
    payload: noteItemEditData,
  });
  store.dispatch({
    type: captureActions.UPDATE_CAPTURE_ITEM,
    payload: {
      payload: {
        content: isFromNoteHeader ? payloadContent : { note: payloadContent },
        captured_location_info: location,
        name: noteItemEditData?.name,
      },
      captureType: 'note',
      itemId: noteItemEditData?.id,
      isCurrentLocationProcess: true,
      isDontUpdateResponse: true,
    },
  });
}

export function getNoteParentDetails() {
  const jotsProjectItems = store.getState().jotsReducer.jotsProjectItems;
  const boardDetails = store.getState().BoardGroups.boardDetails;
  const captureLists = store.getState().Capture.captureLists;
  const addBarDetails = store.getState().addBar.addBarDetails;
  const {
    parent: addBarItemParent = null,
    parentId = null,
    nodeId = null,
    isCapture = false,
  } = addBarDetails;
  if (isCapture || nodeId === 'add-bar') {
    return captureLists || [];
  } else {
    switch (addBarItemParent) {
      case 'jots':
        return jotsProjectItems || [];
      case 'folder':
        return boardDetails || [];
      case 'jots_document':
        return (
          jotsProjectItems.find((item) => item.id === parentId)?.items || []
        );
      case 'files_document':
        return boardDetails.find((item) => item.id === parentId)?.items || [];
      default:
        return [];
    }
  }
}

export function locationHelper({
  capturedAddress = null,
  currentLocationValues = {},
  isLocationVisible = false,
  isEdit = false,
}) {
  const locationDetails = () => {
    if (capturedAddress) {
      return capturedAddress;
    } else if (
      currentLocationValues['addbar-modal']?.isCurrentLocationEnabled
    ) {
      return currentLocationValues['addbar-modal'].currentAddress;
    }
    return <Translate>unable_to_get_the_data</Translate>;
  };
  const showLocation = () => {
    store.dispatch({
      type: actions.SET_LOCATION_VISIBILITY,
      payload: true,
    });
  };
  if (currentLocationValues?.['addbar-modal']?.isCurrentLocationLoader) {
    return (
      <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} />
    );
  } else if (
    !capturedAddress &&
    ((currentLocationValues &&
      currentLocationValues['addbar-modal']?.isCurrentLocationEnabled) ===
      undefined ||
      currentLocationValues === undefined)
  ) {
    return (
      <span
        onClick={() => getCurrentLocation('addbar-modal')}
        className={'show-location'}
      >
        <Translate>show_current_location</Translate>
      </span>
    );
  } else {
    if (!isLocationVisible && !isEdit) {
      return (
        <span onClick={showLocation} className={'show-location'}>
          <Translate>show_current_location</Translate>
        </span>
      );
    } else {
      return <span>{locationDetails()}</span>;
    }
  }
}
