import actions from './actions';
import { addLivePlatformToHeader, parseJwt, setTheme } from 'utility/helpers';
import i18n from 'i18n';
import { sharedSparkListOrder } from 'components/constants';
import { cloneDeep } from 'lodash';
import { tourCompletedStep } from 'components/tour/constants';

const showTipMessagesInitKeyValues = {
    show_all: true,
    unsorted: {
      is_selected: false,
      is_closed: false,
    },
    boards: {
      is_selected: false,
      is_closed: false,
    },
    folders_panel: {
      is_selected: false,
      is_closed: false,
    },
    folder: {
      is_selected: false,
      is_closed: false,
    },
    addbar_modal: {
      is_selected: false,
      is_closed: false,
    },
    search: {
      is_selected: false,
      is_closed: false,
    },
    home_folder: {
      is_selected: false,
      is_closed: false,
    },
    new_board_modal: {
      is_selected: false,
      is_closed: false,
    },
    new_signature_modal: {
      is_selected: false,
      is_closed: false,
    },
    create_spark: {
      is_selected: false,
      is_closed: false,
    },
    add_location: {
      is_selected: false,
      is_closed: false,
    },
    shared_sparks: {
      is_selected: false,
      is_closed: false,
    },
    add_url: {
      is_selected: false,
      is_closed: false,
    },
    new_contact_modal: {
      is_selected: false,
      is_closed: false,
    },
    account_settings_avatar: {
      is_selected: false,
      is_closed: false,
    },
  },
  initState = {
    loading: false,
    email: null,
    isLoggedIn: false,
    id: null,
    refreshingToken: false,
    subscribedPlan: null,
    subscribedPlanType: null,
    subscribedPlanId: null,
    firstName: null,
    lastName: null,
    isVerified: false,
    hasCompletedTour: false,
    remainingVerificationMinutes: null,
    modalVisible: false,
    deactivateReason: [],
    deleteAccountReason: [],
    phoneNumber: null,
    avatar: null,
    deactivated: null,
    deactivatedAt: null,
    workspaceId: null,
    changePasswordVisible: false,
    accountDeactivationVisible: false,
    deactivateConfirmationVisible: false,
    reactivateConfirmationVisible: false,
    deactivationLoader: false,
    activationLoader: false,
    verifyModalVisible: false,
    registerLoader: false,
    loginLoader: false,
    history: undefined,
    isOnboardComplete: undefined,
    locationAccess: {
      loading: false,
      status: false,
    },
    onBoardingModal: {
      visible: false,
      completionModalVisible: false,
      current: 0,
    },
    passwordsList: [],
    reasons: [],
    comments: null,
    activeSessions: {
      loading: false,
      sessions: [],
    },
    sharedSparkDocumentView: {
      newest_first: {
        chronological: 'expand',
        list_expand: sharedSparkListOrder,
      },
      oldest_first: {
        chronological: 'expand',
        list_expand: sharedSparkListOrder,
      },
    },
    planUpgradeMessage: '',
    planUpgradeModal: false,
    accountLanguage: 'en',
    revisionPermissions: {},
    usage: {},
    planStorageLimit: {},
    noOfActiveSession: false,
    activeSessionsStatus: false,
    registrationFormData: '',
    registrationFormVisibility: false,
    redirectModalModalVisible: false,
    lastScreen: [],
    socialLoader: false,
    extensionStoreModalVisible: true,
    userAccountDetails: undefined,
    badCredentialsResponse: undefined,
    socketId: '',
    showTipMessages: {
      show_all: false,
      unsorted: {
        is_selected: true,
        is_closed: true,
      },
      boards: {
        is_selected: true,
        is_closed: true,
      },
      folders_panel: {
        is_selected: true,
        is_closed: true,
      },
      folder: {
        is_selected: true,
        is_closed: true,
      },
      addbar_modal: {
        is_selected: true,
        is_closed: true,
      },
      search: {
        is_selected: true,
        is_closed: true,
      },
      home_folder: {
        is_selected: true,
        is_closed: true,
      },
      new_board_modal: {
        is_selected: true,
        is_closed: true,
      },
      new_signature_modal: {
        is_selected: true,
        is_closed: true,
      },
      create_spark: {
        is_selected: true,
        is_closed: true,
      },
      add_location: {
        is_selected: true,
        is_closed: true,
      },
      shared_sparks: {
        is_selected: true,
        is_closed: true,
      },
      add_url: {
        is_selected: true,
        is_closed: true,
      },
      new_contact_modal: {
        is_selected: true,
        is_closed: true,
      },
      account_settings_avatar: {
        is_selected: true,
        is_closed: true,
      },
    },
    isMultiFileUpload: false,
    trashView: {
      projects: 'expand',
      nodes: 'expand',
      items: 'expand',
      documents: 'expand',
      boardGroups: 'expand',
    },
    theme: 'grey',
    isLoggedOut: false,
    accountDeletedVisible: false,
    freePlanSelectiveSessions: [],
    openAPILoader: false,
    openAPIKey: null,
    hasOnBoardingCompleted: true,
    currentUserPlanStatus: '',
    userOnboardingState: undefined,
    preNamedConfigModelVisibility: false,
    onboardingState: 6,
    getUserDetails: false,
  };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_USER: {
      return {
        ...state,
        loginLoader: true,
      };
    }
    case actions.LOGIN_USER_SOCIAL: {
      return {
        ...state,
        loginLoader: true,
      };
    }
    case actions.LOGIN_USER_SUCCESS: {
      const { payload } = action,
        {
          id,
          token,
          is_email_verified,
          remaining_verification_minutes,
          deactivated,
          last_accessed_page,
        } = payload;
      return {
        ...state,
        loginLoader: false,
        socialLoader: false,
        isLoggedIn: true,
        extensionStoreModalVisible: true,
        id,
        token: token,
        subscribedPlan: parseJwt(token)['plan_id'],
        subscribedPlanId: parseJwt(token)['plan_id'],
        isVerified: is_email_verified,
        remainingVerificationMinutes: remaining_verification_minutes,
        deactivated,
        redirectModalModalVisible: !!last_accessed_page,
        lastScreen: last_accessed_page?.last_accessed_page || [],
      };
    }
    case actions.LOGIN_USER_FAILURE: {
      return {
        ...state,
        loginLoader: false,
        socialLoader: false,
        badCredentialsResponse: action.payload,
      };
    }
    case actions.SET_SOCIAL_LOADER: {
      return {
        ...state,
        socialLoader: !action.payload,
      };
    }
    case actions.LOGOUT_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.LOGOUT_USER_SUCCESS: {
      return {
        ...state,
        isLoggedIn: false,
        email: null,
        id: null,
        refreshingToken: false,
        subscribedPlan: null,
        firstName: null,
        lastName: null,
        loading: false,
        remainingVerificationMinutes: null,
        accountDeletedVisible: action.accountDeletedVisible || false,
      };
    }
    case actions.LOGOUT_USER_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.REFRESH_TOKEN: {
      return {
        ...state,
        refreshingToken: action.isFromBoot || state.refreshingToken,
      };
    }
    case actions.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
        deactivated: action.payload.deactivated,
        isLoggedIn: true,
        subscribedPlanId: parseJwt(action.payload.token)['plan_id'],
        isVerified: action.payload.is_email_verified,
        refreshingToken: false,
      };
    }
    case actions.REFRESH_TOKEN_FAILURE: {
      return {
        ...state,
        refreshingToken: false,
        isLoggedIn: false,
        email: null,
        id: null,
        subscribedPlan: null,
        firstName: null,
        lastName: null,
        loading: false,
        remainingVerificationMinutes: null,
      };
    }
    case actions.REGISTER_USER: {
      addLivePlatformToHeader();
      return {
        ...state,
        registerLoader: true,
      };
    }
    case actions.REGISTER_USER_SOCIAL: {
      addLivePlatformToHeader();
      return {
        ...state,
        registerLoader: true,
      };
    }
    case actions.REGISTER_USER_SUCCESS: {
      return {
        ...state,
        registerLoader: false,
        userOnboardingState: 'registered',
      };
    }
    case actions.UPDATE_USER_VERIFICATION_PROCESS: {
      return {
        ...state,
        userOnboardingState: 'account_verified',
      };
    }
    case actions.REGISTER_USER_FAILURE: {
      return {
        ...state,
        registerLoader: false,
      };
    }
    case actions.GET_USER_DETAILS: {
      return {
        ...state,
        getUserDetails: false,
      };
    }
    case actions.GET_USER_DETAILS_SUCCESS: {
      const { payload, isFromRegisterPanel, isFromPusher } = action,
        theme = payload?.app_theme || 'grey';
      window.analytics && window.analytics.identify(payload.user_id, {
        email: payload.email,
        phoneNumber: payload.phone_number,
        avatar: payload.avatar,
        'Account Deactivated': payload.deactivated,
        deactivatedAt: payload.deactivated_at,
        firstName: payload?.first_name,
        lastName: payload?.last_name,
        'Workspace Id': payload.workspace_id,
      });
      localStorage.setItem('userId', payload.user_id);
      localStorage.setItem('user', JSON.stringify(payload));
      !isFromRegisterPanel &&
        localStorage.setItem(`firstEntryExperience-${payload.user_id}`, true);
      i18n.changeLanguage(payload.language);
      setTheme(theme);
      return {
        ...state,
        email: payload.email,
        phoneNumber: payload.phone_number,
        avatar: payload.avatar,
        deactivated:
          payload.deactivated ||
          payload?.plan_id === '' ||
          payload?.plan_id === null,
        deactivatedAt: payload.deactivated_at,
        firstName: payload?.first_name,
        lastName: payload?.last_name,
        workspaceId: payload.workspace_id,
        remainingVerificationMinutes: payload.remaining_verification_minutes,
        isVerified: payload.is_email_verified,
        subscribedPlan: payload.plan,
        subscribedPlanType: payload.plan_type,
        subscribedPlanId: payload.plan_id,
        isOnboardComplete: payload.is_onboard_complete,
        locationAccess: {
          loading: false,
          status: payload.location_access,
        },
        sharedSparkDocumentView: payload.shared_spark_document_view
          ? payload.shared_spark_document_view
          : state.sharedSparkDocumentView,
        accountLanguage: payload.language,
        revisionPermissions: payload.revision_permissions,
        usage: payload.usage,
        planStorageLimit: {
          storageLimit: payload.storage_limit,
          used: payload.used,
          trashStorageUsed: payload?.usage?.storage_used_in_trash,
        },
        noOfActiveSession: payload.no_of_active_session,
        userAccountDetails: payload,
        activeSessionsStatus:
          payload.revision_permissions.sync_limit !== null &&
          payload.no_of_active_session >
            payload.revision_permissions.sync_limit,
        showTipMessages: payload.tip_messages_info || {
          show_all: false,
          unsorted: {
            is_selected: true,
            is_closed: true,
          },
          boards: {
            is_selected: true,
            is_closed: true,
          },
          folders_panel: {
            is_selected: true,
            is_closed: true,
          },
          folder: {
            is_selected: true,
            is_closed: true,
          },
          addbar_modal: {
            is_selected: true,
            is_closed: true,
          },
          search: {
            is_selected: true,
            is_closed: true,
          },
          home_folder: {
            is_selected: true,
            is_closed: true,
          },
          new_board_modal: {
            is_selected: true,
            is_closed: true,
          },
          new_signature_modal: {
            is_selected: true,
            is_closed: true,
          },
          create_spark: {
            is_selected: true,
            is_closed: true,
          },
          add_location: {
            is_selected: true,
            is_closed: true,
          },
          shared_sparks: {
            is_selected: true,
            is_closed: true,
          },
          add_url: {
            is_selected: true,
            is_closed: true,
          },
          new_contact_modal: {
            is_selected: true,
            is_closed: true,
          },
          account_settings_avatar: {
            is_selected: true,
            is_closed: true,
          },
        },
        trashView: payload.trash_document_view || state.trashView,
        theme: theme,
        plan: payload.plan,
        currentPlanType: payload.current_plan_type,
        capturesCleanupLimit: payload.captures_cleanup_limit,
        openAPIKey: payload.openai_key || state.openAPIKey,
        froalaEditorModel: payload['froala-editor-model'] || 'gpt-3.5-turbo-instruct',
        currentUserPlanStatus: isFromPusher
          ? state.currentUserPlanStatus
          : payload.status,
        hasOnBoardingCompleted: true,
        planExpiredOn: payload.plan_expired_on,
        tourOnboardingStep:
          payload?.has_completed_tour === true
            ? tourCompletedStep
            : payload.onboarding_state,
        getUserDetails: true,
        hasCompletedTour: payload?.has_completed_tour,
      };
    }
    case actions.GET_USER_DETAILS_FAILURE: {
      return {
        ...state,
        getUserDetails: false,
      };
    }
    case actions.MODAL_VISIBLE: {
      return {
        ...state,
        modalVisible: action.payload,
      };
    }
    case actions.FETCH_DEACTIVATE_REASON_LIST_SUCCESS: {
      return {
        ...state,
        deactivateReason: action.payload,
      };
    }
    case actions.FORGOT_PASSWORD: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.RESET_PASSWORD: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        modalVisible: true,
      };
    }
    case actions.RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.UPDATE_USER_DETAILS: {
      return {
        ...state,
        loading: true,
      };
    }
    case actions.UPDATE_USER_DETAILS_SUCCESS: {
      const { payload } = action;
      localStorage.setItem('userId', payload.user_id);
      return {
        ...state,
        email: payload.email,
        phoneNumber: payload.phone_number,
        avatar: payload.avatar,
        deactivated: payload.deactivated,
        deactivatedAt: payload.deactivated_at,
        firstName: payload?.first_name,
        lastName: payload?.last_name,
        workspaceId: payload.workspace_id,
        remainingVerificationMinutes: payload.remaining_verification_minutes,
        loading: false,
      };
    }
    case actions.UPDATE_USER_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case actions.CHANGE_PASSWORD_VISIBLE: {
      return {
        ...state,
        changePasswordVisible: action.payload,
      };
    }
    case actions.ACCOUNT_DEACTIVATION_VISIBLE: {
      const { payload, reasons, comments } = action;
      return {
        ...state,
        accountDeactivationVisible: payload,
        reasons,
        comments,
      };
    }
    case actions.DEACTIVATE_ACCOUNT: {
      return {
        ...state,
        deactivationLoader: true,
      };
    }
    case actions.DEACTIVATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        deactivationLoader: false,
        deactivated: true,
      };
    }
    case actions.DEACTIVATE_ACCOUNT_FAILURE: {
      return {
        ...state,
        deactivationLoader: false,
      };
    }
    case actions.DEACTIVATE_CONFIRMATION_VISIBLE: {
      return {
        ...state,
        deactivateConfirmationVisible: action.payload,
      };
    }
    case actions.REACTIVATE_CONFIRMATION_VISIBLE: {
      return {
        ...state,
        reactivateConfirmationVisible: action.payload,
      };
    }
    case actions.VERIFY_MODAL_VISIBLE: {
      return {
        ...state,
        verifyModalVisible: action.payload,
      };
    }
    case actions.IMMEDIATE_VERIFY_MODAL_VISIBLE: {
      const { payload, isFromLogin } = action;
      return {
        ...state,
        immediateVerifyModal: payload,
        isFromLogin,
      };
    }
    case actions.CHANGE_USER_AVATAR: {
      return {
        ...state,
        avatar: null,
        userAvatarLoader: true,
      };
    }
    case actions.CHANGE_USER_AVATAR_SUCCESS: {
      return {
        ...state,
        avatar: action.payload.avatar,
        userAvatarLoader: false,
      };
    }
    case actions.ACTIVATE_ACCOUNT: {
      return {
        ...state,
        activationLoader: true,
      };
    }
    case actions.ACTIVATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        activationLoader: false,
        deactivated: false,
      };
    }
    case actions.ACTIVATE_ACCOUNT_FAILURE: {
      return {
        ...state,
        activationLoader: false,
      };
    }
    case actions.SET_HISTORY: {
      return {
        ...state,
        history: action.history,
      };
    }
    case actions.CHANGE_USER_AVATAR_LOADER: {
      return {
        ...state,
        userAvatarLoader: action.payload,
      };
    }
    case actions.SET_ON_BOARDING_MODAL_VISIBLE: {
      const {
        visible,
        completionModalVisible,
        current,
        isOnboardComplete,
      } = action;
      return {
        ...state,
        isOnboardComplete: [true, false].includes(isOnboardComplete)
          ? isOnboardComplete
          : state.isOnboardComplete,
        onBoardingModal: {
          ...state.onBoardingModal,
          visible: [true, false].includes(visible)
            ? visible
            : state.onBoardingModal.visible,
          completionModalVisible: [true, false].includes(completionModalVisible)
            ? completionModalVisible
            : state.onBoardingModal.completionModalVisible,
          current:
            current || current === 0 ? current : state.onBoardingModal.current,
        },
      };
    }
    case actions.SET_LOCATION_ACCESS_STATUS_SUCCESS: {
      return {
        ...state,
        locationAccess: {
          loading: false,
          status: action.status,
        },
      };
    }
    case actions.GET_PASSWORDS_LIST_SUCCESS: {
      return {
        ...state,
        passwordsList: action.passwordsList,
      };
    }
    case actions.GET_ACTIVE_SESSIONS: {
      return {
        ...state,
        activeSessions: {
          loading: true,
        },
      };
    }
    case actions.GET_ACTIVE_SESSIONS_SUCCESS: {
      return {
        ...state,
        activeSessions: {
          loading: false,
          sessions: action.activeSessions,
        },
      };
    }
    case actions.GET_ACTIVE_SESSIONS_FAILURE: {
      return {
        ...state,
        activeSessions: {
          loading: false,
          sessions: initState.activeSessions.sessions,
        },
      };
    }
    case actions.REVOKE_SPECIFIC_SESSION: {
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          loading: true,
        },
      };
    }
    case actions.REVOKE_SPECIFIC_SESSION_SUCCESS: {
      return {
        ...state,
        activeSessions: {
          ...state.activeSessions,
          loading: false,
          sessions: state.activeSessions.sessions.filter(
            (data) => data.id !== action.revokedSessionId,
          ),
        },
      };
    }
    case actions.REVOKE_SPECIFIC_SESSION_FAILURE: {
      return {
        ...state,
        activeSessions: {
          loading: false,
        },
      };
    }
    case actions.SET_SHARED_SPARK_EXPAND_DETAILS: {
      return {
        ...state,
        sharedSparkDocumentView: action.payload.shared_spark_document_view,
      };
    }
    case actions.CHANGE_UPGRADE_MODAL_VISIBILITY: {
      return {
        ...state,
        planUpgradeMessage: action.payload.planUpgradeMessage,
        planUpgradeModal: action.payload.planUpgradeModal,
      };
    }
    case actions.UPDATE_DEVICE_LANGUAGE: {
      return {
        ...state,
        accountLanguage: action.payload,
      };
    }
    case actions.UPDATE_PLAN_DETAILS: {
      return {
        ...state,
        planStorageLimit: {
          ...state.planStorageLimit,
          used: action.payload,
        },
      };
    }
    case actions.UPDATE_ACTIVE_SESSION_DETAILS: {
      return {
        ...state,
        noOfActiveSession: action.payload,
        activeSessionsStatus:
          state.revisionPermissions.sync_limit !== null &&
          action.payload > state.revisionPermissions.sync_limit,
      };
    }
    case actions.SHOW_REGISTRATION_FORM: {
      return {
        ...state,
        registrationFormData: action.payload,
        registrationFormVisibility: action.visibility,
        socialLoader: false,
        registerLoader: false,
      };
    }
    case actions.UPDATE_PROFILE_DETAILS: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    case actions.RESET_REDIRECT_MODAL: {
      return {
        ...state,
        redirectModalModalVisible: false,
      };
    }
    case actions.SET_EXTENSION_STORE_VISIBLE_MODAL: {
      const { visible } = action;
      return {
        ...state,
        extensionStoreModalVisible: visible,
      };
    }
    case actions.SET_SOCKET_ID: {
      const { payload } = action;
      return {
        ...state,
        socketId: payload,
      };
    }
    case actions.SET_SHOW_TIPS_STATUS: {
      const { payload, infoKeyValue } = action;
      return {
        ...state,
        showTipMessages: {
          ...state.showTipMessages,
          [infoKeyValue]: payload,
        },
      };
    }
    case actions.SET_SHOW_ALL_TIPS_STATUS: {
      const { payload, infoKeyValue } = action;
      let newShowTipMessages = cloneDeep(state.showTipMessages);
      if (infoKeyValue === 'show_all' && payload) {
        newShowTipMessages = {
          ...showTipMessagesInitKeyValues,
        };
      } else if (infoKeyValue === 'show_all' && !payload) {
        newShowTipMessages = {
          ...newShowTipMessages,
          [infoKeyValue]: payload,
        };
      } else {
        newShowTipMessages = {
          ...state.showTipMessages,
          [infoKeyValue]: payload,
        };
      }
      return {
        ...state,
        showTipMessages: newShowTipMessages,
      };
    }
    case actions.SET_MULTI_FILE_UPLOAD: {
      const { payload } = action;
      return {
        ...state,
        isMultiFileUpload: payload,
      };
    }
    case actions.UPDATE_TRASH_EXPAND_COLLAPSE: {
      return {
        ...state,
        trashView: action.payload.trash_document_view,
      };
    }
    case actions.UPDATE_TRASH_STORAGE_LIMIT_AND_CONSUMPTION: {
      const { payload } = action;
      return {
        ...state,
        planStorageLimit: {
          ...state.planStorageLimit,
          used: payload.storage_used,
          trashStorageUsed: payload.storage_used_in_trash,
        },
      };
    }
    case actions.UPDATE_CREATE_PROJECTS_COUNT: {
      return {
        ...state,
        usage: {
          ...state.usage,
          create_projects_count: action.payload,
        },
      };
    }
    case actions.UPDATE_APP_THEME: {
      return {
        ...state,
        theme: action.theme,
      };
    }
    case actions.ENTRY_PANEL_VALUES: {
      return {
        ...state,
        values: {
          clickValue: action.value,
          clickLocation: action.location,
        },
      };
    }
    case actions.ENTRY_PANEL_VISIBILITY: {
      return {
        ...state,
        entryPanelVisibility: action.payload,
      };
    }
    case actions.ENTRY_PANEL_BUTTONS: {
      return {
        ...state,
        entryPanelButtons: action.payload,
      };
    }
    case actions.ENTRY_PANEL_TOGGLE: {
      return {
        ...state,
        entryPanelToggle: action.payload,
      };
    }
    case actions.SET_HOME_ICON: {
      return {
        ...state,
        setHomeIcon: action.payload,
      };
    }
    case actions.CONTINUE_DELETE_MODAL: {
      return {
        ...state,
        continueDeleteModalVisibility: action.payload,
      };
    }
    case actions.DELETE_ACCOUNT: {
      return {
        ...state,
        deleteLoader: true,
      };
    }
    case actions.DELETE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        deleteLoader: false,
        isAccountDeleted: true,
      };
    }
    case actions.DELETE_ACCOUNT_FAILURE: {
      return {
        ...state,
        deleteLoader: false,
      };
    }
    case actions.ACCOUNT_DELETION_VISIBLE: {
      const { payload, reasons, comments } = action;
      return {
        ...state,
        accountDeletionVisible: payload,
        reasons,
        comments,
      };
    }
    case actions.ACCOUNT_DELETED_MODAL: {
      return {
        ...state,
        accountDeletedVisible: action.payload,
      };
    }
    case actions.DOWNLOAD_ASSETS: {
      return {
        ...state,
        downloadAssets: action.payload,
      };
    }
    case actions.FETCH_DELETE_ACCOUNT_REASON_LIST_SUCCESS: {
      return {
        ...state,
        deleteAccountReason: action.payload,
      };
    }
    case actions.UPDATE_SELECTED_SESSION_ID: {
      const { sessionId, checked } = action;
      let updatedSessionID = [...state.freePlanSelectiveSessions];
      if (checked) {
        updatedSessionID.push(sessionId);
      } else {
        updatedSessionID = updatedSessionID.filter((id) => id !== sessionId);
      }
      return {
        ...state,
        freePlanSelectiveSessions: updatedSessionID,
      };
    }
    case actions.VALIDATE_REGISTER_EMAIL: {
      return {
        ...state,
        registerLoader: true,
      };
    }
    case actions.VALIDATE_REGISTER_EMAIL_FAILURE: {
      const { error } = action;
      return {
        ...state,
        checkEmailFailure: error,
        registerLoader: false,
      };
    }
    case actions.UPDATE_OPEN_API_KEY: {
      return {
        ...state,
        openAPILoader: true,
      };
    }
    case actions.UPDATE_OPEN_API_KEY_SUCCESS: {
      return {
        ...state,
        openAPILoader: false,
        openAPIKey: action.payload.openai_key || state.openAPIKey,
      };
    }
    case actions.UPDATE_OPEN_API_KEY_FAILURE: {
      return {
        ...state,
        openAPILoader: false,
      };
    }
    case actions.UPDATE_ON_BOARDING_STATUS: {
      return {
        ...state,
        preConfigLoader: true,
        onBoardingStatusLoader: true,
      };
    }
    case actions.UPDATE_ON_BOARDING_STATUS_SUCCESS: {
      return {
        ...state,
        onBoardingStatusLoader: action.payload,
        preConfigLoader: false,
        hasOnBoardingCompleted: true,
      };
    }
    case actions.UPDATE_ON_BOARDING_STATUS_FAILURE: {
      return {
        ...state,
        onBoardingStatusLoader: false,
        preConfigLoader: false,
      };
    }
    case actions.UPDATE_USER_PLAN_STATUS: {
      return {
        ...state,
        currentUserPlanStatus: action.payload,
      };
    }
    case actions.FORM_INITIATION: {
      return {
        ...state,
        checkEmailFailure: '',
      };
    }
    case actions.UPDATE_ONBOARDING_TOUR_STEPS: {
      return {
        ...state,
        tourOnboardingStep: action.payload,
      };
    }
    case actions.INVOKE_PRE_NAMED_CONFIG_MODAL: {
      return {
        ...state,
        preNamedConfigModelVisibility: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
