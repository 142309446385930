import { combineReducers } from 'redux';
import Auth from 'redux/auth/reducer';
import commonReducer from 'redux/common/reducer';
import Settings from 'redux/settings/reducer';
import Subscription from 'redux/subscription/reducer';
import TourReducer from './tour/reducer';
import authActions from 'redux/auth/actions';

const staticReducers = {
  Auth,
  commonReducer,
  Settings,
  Subscription,
  TourReducer,
};

const createReducer = (asyncReducers = {}) =>
  combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });

// Root reducer to handle RESET_STORE action
const rootReducer = (state, action) => {
  if (action.type === authActions.RESET_STORE) {
    state = undefined;
  }
  return createReducer()(state, action);
};

export default rootReducer;

// Export a function to create a new reducer with async reducers
export const createRootReducer = (asyncReducers) => (state, action) => {
  if (action.type === authActions.RESET_STORE) {
    state = undefined;
  }
  return createReducer(asyncReducers)(state, action);
};
