const actions = {
  GET_SEARCH_RESULTS: 'GET_SEARCH_RESULTS',
  GET_SEARCH_RESULTS_SUCCESS: 'GET_SEARCH_RESULTS_SUCCESS',
  GET_SEARCH_RESULTS_FAILURE: 'GET_SEARCH_RESULTS_FAILURE',
  CLEAR_SEARCH_RESULTS: 'CLEAR_SEARCH_RESULTS',
  CLEAR_SEARCH_TERMS: 'CLEAR_SEARCH_TERMS',
  SET_SEARCHED_VALUES: 'SET_SEARCHED_VALUES',
  GET_SUGGESTION_LISTS: 'GET_SUGGESTION_LISTS',
  GET_SUGGESTION_LISTS_SUCCESS: 'GET_SUGGESTION_LISTS_SUCCESS',
  GET_RECENT_SEARCHED_VALUES: 'GET_RECENT_SEARCHED_VALUES',
  GET_RECENT_SEARCHED_VALUES_SUCCESS: 'GET_RECENT_SEARCHED_VALUES_SUCCESS',
  GET_RECENT_SEARCHED_VALUES_FAILURE: 'GET_RECENT_SEARCHED_VALUES_FAILURE',
  CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
  UPDATE_RECENT_SEARCHED_VALUES: 'UPDATE_RECENT_SEARCHED_VALUES',
  ADVANCED_SEARCH: 'ADVANCED_SEARCH',
  SEARCH_MODAL_VISIBILITY: 'SEARCH_MODAL_VISIBILITY',
  DROPDOWN_VISIBILITY: 'DROPDOWN_VISIBILITY',
  SORT_TYPE: 'SORT_TYPE',
  SET_SELECTED_RESULT_ITEM_DETAILS: 'SET_SELECTED_RESULT_ITEM_DETAILS',
  GET_FILES_COUNT: 'GET_FILES_COUNT',
  GET_FILES_COUNT_SUCCESS: 'GET_FILES_COUNT_SUCCESS',
  GET_FILES_COUNT_FAILURE: 'GET_FILES_COUNT_FAILURE',
};

export default actions;
